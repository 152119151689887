import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import { SITE_URL } from '../../utils/constants/urls';
import { isWeb } from '../../utils/dimensions';
import { LinkParams } from './types';
import openShareLinkForNative from './common/openShareLinkForNative';
import { SAML_METADATA_URL } from '../../utils/constants';

interface Args {
  biteShareId: number;
  biteSubject: string;
  afterShareAction?: () => void;
  isBeekeeperOrg: { authUrl: string } | boolean;
}

const useOpenShareBiteLink = () => {
  const { t } = useTranslation();

  const openShareBiteLink = async ({ biteShareId, biteSubject, afterShareAction, isBeekeeperOrg }: Args) => {
    const { link, copyTitle, copyText } = getOpenShareBiteLinkParams({
      biteShareId,
      biteSubject,
      t,
      isBeekeeperOrg,
    });

    if (isWeb) {
      openShareBiteLinkForWeb({ link, t });
    } else {
      await openShareLinkForNative({ copyTitle, copyText, afterShareAction });
    }
  };

  return openShareBiteLink;
};

export default useOpenShareBiteLink;

export const getOpenShareBiteLinkParams = ({ biteShareId, biteSubject, t, isBeekeeperOrg }): LinkParams => {
  const defaultLink = `${SITE_URL}/bites/${biteShareId}?timestamp=${Date.now()}`;
  const link = isBeekeeperOrg
    ? `${isBeekeeperOrg.authUrl}?entity_id=${SAML_METADATA_URL}&target=${defaultLink}`
    : defaultLink;
  const copyTitle = t('openShareLink.biteCopyTitle');
  const copyText = `${biteSubject}\n${link}`;
  return { link, copyTitle, copyText };
};

const openShareBiteLinkForWeb = ({ link, t }) => {
  const toastText = t('openShareLink.biteToast');
  toast(toastText);
  copy(`${link}`, { debug: true });
};
