import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import calculatePercentages from '../../../utils/calculatePercentages';
import { EQuestionType } from '../../../types/biteQuestion';
import { EAnalyticsScreenType } from '../../../screens/analytics/Analytics.types';
import { prepareAnalytics, resetAnalytics } from '../../analytics/analytics.slice';
import {
  EAnsweredFilterValue,
  ECommentedFilterValue,
  EEngagedFilterValue,
  IAnalyticsAnswersListItem,
  IAnalyticsBiteQuestion,
  IAnalyticsCommentsListItem,
  IAnalyticsViewsListItem,
  IInitialState,
} from './biteStats.types';

export const initialState: IInitialState = {
  overview: {
    views: {
      isLoading: true,
      isRequestInProgress: false,
      error: null,
      data: null,
    },
    comments: {
      isLoading: true,
      isRequestInProgress: false,
      error: null,
      data: null,
    },
    answers: {
      isLoading: true,
      isRequestInProgress: false,
      error: null,
      data: null,
    },
  },
  stats: {
    overview: {
      isNeedToScrollUp: false,
      error: null,
      filter: EEngagedFilterValue.ALL,
      isLoading: false,
      data: null,
      next: null,
    },
    views: {
      isNeedToScrollUp: false,
      error: null,
      filter: EEngagedFilterValue.ALL,
      isLoading: false,
      data: null,
      next: null,
    },
    answers: {
      isNeedToScrollUp: false,
      error: null,
      filter: {
        answerIds: [],
        answered: EAnsweredFilterValue.ALL,
      },
      isLoading: false,
      data: null,
      next: null,
    },
    comments: {
      isNeedToScrollUp: false,
      error: null,
      filter: ECommentedFilterValue.ALL,
      isLoading: false,
      data: null,
      next: null,
    },
    aggregatedAnswers: {
      isLoading: false,
      error: null,
      data: {
        question: null,
        aggregated: {
          percentage: null,
          absolute: null,
        },
      },
    },
  },
};

const BITE_STATS = 'BITE_STATS';

export const getBiteDistributions = createAction(`${BITE_STATS}/getBiteDistributions`);

export const getBiteStatsOverview = createAction(`${BITE_STATS}/getBiteStatsOverview`);

interface IGetAnalyticsDataPayload {
  reset: boolean;
  clearAnalyticsCache?: boolean;
  callback?: () => void;
}

const biteStatsSlice = createSlice({
  name: BITE_STATS,
  initialState,
  reducers: {
    getAnswersAggregated: (state) => {
      state.stats.aggregatedAnswers.isLoading = true;
      state.stats.aggregatedAnswers.error = null;
    },
    setAnswersAggregated: (
      state,
      { payload }: PayloadAction<{ question: IAnalyticsBiteQuestion; aggregated: { [key: string]: number } }>,
    ) => {
      let absolute = null;
      let rounded = null;

      if (payload.question.type !== EQuestionType.OPEN_ENDED) {
        absolute = {};

        payload.question.choices.forEach((choice) => {
          absolute[choice.id] = payload.aggregated[choice.id] || 0;
        });

        const { roundedPercentages } = calculatePercentages(absolute);
        rounded = roundedPercentages;
      }

      state.stats.aggregatedAnswers.data.aggregated.absolute = absolute;
      state.stats.aggregatedAnswers.data.aggregated.percentage = rounded;
      state.stats.aggregatedAnswers.data.question = payload.question;
      state.stats.aggregatedAnswers.isLoading = false;
    },
    setAggregatedAnswersError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.aggregatedAnswers.error = payload.error;
    },
    getOverviewData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.stats.overview.isLoading = true;
      state.stats.overview.error = null;
      if (payload?.reset) {
        state.stats.overview.next = null;
      }
    },
    getViewsData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.stats.views.isLoading = true;
      state.stats.views.error = null;
      if (payload?.reset) {
        state.stats.views.next = null;
      }
    },
    getAnswersData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.stats.answers.isLoading = true;
      state.stats.answers.error = null;
      if (payload?.reset) {
        state.stats.answers.next = null;
      }
    },
    getCommentsData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.stats.comments.isLoading = true;
      state.stats.comments.error = null;
      if (payload?.reset) {
        state.stats.comments.next = null;
      }
    },
    setOverviewData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsViewsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.stats.overview.next && !payload.reset) {
        state.stats.overview.data = state.stats.overview.data.concat(payload.results);
      } else {
        state.stats.overview.data = payload.results;
      }
      state.stats.overview.next = payload.next;
      state.stats.overview.isLoading = false;
    },
    setViewsData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsViewsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.stats.views.next && !payload.reset) {
        state.stats.views.data = state.stats.views.data.concat(payload.results);
      } else {
        state.stats.views.data = payload.results;
      }
      state.stats.views.next = payload.next;
      state.stats.views.isLoading = false;
    },
    setAnswersData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsAnswersListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.stats.answers.next && !payload.reset) {
        state.stats.answers.data = state.stats.answers.data.concat(payload.results);
      } else {
        state.stats.answers.data = payload.results;
      }
      state.stats.answers.next = payload.next;
      state.stats.answers.isLoading = false;
    },
    setCommentsData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsCommentsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.stats.comments.next && !payload.reset) {
        state.stats.comments.data = state.stats.comments.data.concat(payload.results);
      } else {
        state.stats.comments.data = payload.results;
      }
      state.stats.comments.next = payload.next;
      state.stats.comments.isLoading = false;
    },
    setOverviewFilter: (state, { payload }: PayloadAction<EEngagedFilterValue>) => {
      state.stats.overview.filter = payload;
      state.stats.overview.next = null;
    },
    setViewsFilter: (state, { payload }: PayloadAction<EEngagedFilterValue>) => {
      state.stats.views.filter = payload;
      state.stats.views.next = null;
    },
    setAnswersFilter: (state, { payload }: PayloadAction<EAnsweredFilterValue>) => {
      state.stats.answers.filter.answered = payload;
      state.stats.answers.next = null;
    },
    setAnswersIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.stats.answers.filter.answerIds = payload;
      state.stats.answers.next = null;
    },
    setCommentsFilter: (state, { payload }: PayloadAction<ECommentedFilterValue>) => {
      state.stats.comments.filter = payload;
      state.stats.comments.next = null;
    },
    setOverviewDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.overview.error = payload.error;
      state.stats.overview.isLoading = false;
    },
    setViewsDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.views.error = payload.error;
      state.stats.views.isLoading = false;
    },
    setAnswersDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.answers.error = payload.error;
      state.stats.answers.isLoading = false;
    },
    setCommentsDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.comments.error = payload.error;
      state.stats.comments.isLoading = false;
    },
    setIsNeedToScrollUp: (
      state,
      { payload: { listType, status } }: PayloadAction<{ listType: EAnalyticsScreenType; status: boolean }>,
    ) => {
      state.stats[listType].isNeedToScrollUp = status;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getViewsNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.overview.views.isLoading = true;
      state.overview.views.error = null;
      state.overview.views.isRequestInProgress = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAnswersNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.overview.answers.isLoading = true;
      state.overview.answers.error = null;
      state.overview.answers.isRequestInProgress = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCommentsNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.overview.comments.isLoading = true;
      state.overview.comments.error = null;
      state.overview.comments.isRequestInProgress = true;
    },
    setViewsNum: (state, { payload }: PayloadAction<number>) => {
      state.overview.views.data = payload;
      state.overview.views.isLoading = false;
      state.overview.views.isRequestInProgress = false;
    },
    setAnswersNum: (state, { payload }: PayloadAction<number>) => {
      state.overview.answers.data = payload;
      state.overview.answers.isLoading = false;
      state.overview.answers.isRequestInProgress = false;
    },
    setCommentsNum: (state, { payload }: PayloadAction<number>) => {
      state.overview.comments.data = payload;
      state.overview.comments.isLoading = false;
      state.overview.comments.isRequestInProgress = false;
    },
    setViewsNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.overview.views.error = payload.error;
      state.overview.views.isLoading = false;
      state.overview.views.isRequestInProgress = false;
    },
    setAnswersNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.overview.answers.error = payload.error;
      state.overview.answers.isLoading = false;
      state.overview.answers.isRequestInProgress = false;
    },
    setCommentsNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.overview.comments.error = payload.error;
      state.overview.comments.isLoading = false;
      state.overview.comments.isRequestInProgress = false;
    },
    resetViewsNum: (state) => {
      state.overview.views = initialState.overview.views;
    },
    resetAnswersNum: (state) => {
      state.overview.answers = initialState.overview.answers;
    },
    resetCommentsNum: (state) => {
      state.overview.comments = initialState.overview.comments;
    },
  },
  extraReducers: {
    [resetAnalytics.type]: (state, { payload }) => {
      const { excludeFields, withFiltersReset } = payload;
      const exclude = excludeFields?.biteStats;

      if (!exclude && withFiltersReset) {
        return initialState;
      }

      Object.keys(state.stats).forEach((key) => {
        if (exclude?.some((field) => typeof field === 'string' && field === key)) {
          return;
        }

        const newState = { ...initialState.stats[key] };

        if (!withFiltersReset && state.stats[key]?.filter) {
          newState.filter = state.stats[key].filter;
        }

        state.stats[key] = newState;
      });
      state.overview = initialState.overview;
    },
    [prepareAnalytics.type]: () => {
      return initialState;
    },
  },
});

export const {
  setAnswersAggregated,
  getOverviewData,
  getViewsData,
  getAnswersData,
  getCommentsData,
  setOverviewData,
  setViewsData,
  setAnswersData,
  setCommentsData,
  setOverviewFilter,
  setViewsFilter,
  setAnswersFilter,
  setAnswersIdsFilter,
  setCommentsFilter,
  getAnswersAggregated,
  setAggregatedAnswersError,
  setOverviewDataError,
  setViewsDataError,
  setAnswersDataError,
  setCommentsDataError,
  getViewsNum,
  getAnswersNum,
  getCommentsNum,
  setViewsNum,
  setAnswersNum,
  setCommentsNum,
  setViewsNumError,
  setAnswersNumError,
  setCommentsNumError,
  resetViewsNum,
  resetAnswersNum,
  resetCommentsNum,
  setIsNeedToScrollUp,
} = biteStatsSlice.actions;

export default biteStatsSlice.reducer;
