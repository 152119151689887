import React, { memo, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { RefreshControl, ViewStyle } from 'react-native';
import { StyledProps } from 'styled-components';
import { resetAnalytics } from '../../../store/analytics/analytics.slice';
import AnalyticsScreenHeader from '../../analytics/common/AnalyticsScreenHeader';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import RestrictedDataBanner from '../common/RestrictedDataBanner';
import Routes from '../../../navigation/routes';
import { fetchFullPlaylists } from '../../../store/playlist/playlist.actions';
import PlaylistOverview from './PlaylistOverview';
import PlaylistStatsOverview from './PlaylistStatsOverview';
import {
  getPlaylistQuestions,
  getPlaylistStatsOverview,
} from '../../../store/attributes/playlistStats/playlistStats.slice';

const PlaylistScreen = () => {
  const dispatch = useDispatch();
  const playlistId = useSelector(contentIdSelector);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = useCallback(() => {
    dispatch(log({ event: 'PlaylistScreen.handleRefresh', data: { playlistId } }));
    setIsRefreshing(true);
    dispatch(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          playlistStats: ['playlistQuestionNumber'],
        },
      }),
    );
    dispatch(fetchFullPlaylists([playlistId]));
    dispatch(getPlaylistQuestions());
    dispatch(getPlaylistStatsOverview());
  }, [playlistId, dispatch]);

  useEffect(() => {
    if (isRefreshing) {
      setIsRefreshing(false);
    }
  }, [isRefreshing]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: Routes.AnalyticsStack.PlaylistOverview },
      }),
    );

    return () => {
      dispatch(log({ event: 'PlaylistScreen.exit', data: { playlistId } }));
    };
  }, [playlistId, dispatch]);

  return (
    <S.Container
      contentContainerStyle={S.ContentContainerStyles}
      refreshControl={<S.RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />}
    >
      <AnalyticsScreenHeader />

      <PlaylistOverview isRefreshing={isRefreshing} />
      <PlaylistStatsOverview isRefreshing={isRefreshing} />
      <RestrictedDataBanner />
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView``,
  ContentContainerStyles: { alignItems: 'center' } as StyledProps<ViewStyle>,
  RefreshControl: styled(RefreshControl)``,
};

export default memo(PlaylistScreen);
