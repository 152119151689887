import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  playlistDisplayModeSelector,
  guestsAttributeSelector,
  isGuestsAttributeLoadedSelector,
  playlistSharedWithFilterSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import React, { useCallback, useEffect, useMemo } from 'react';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { getPlaylistGuestsAttribute } from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import AttributeValueItem from './PlaylistAttributeValueItem';
import styled from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import { calcHeight } from '../../../../utils/dimensions';
import {
  PlaylistAttributeValue,
  PlaylistStats,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.types';
import { ESharedWithFilter } from '../../../../types/anayltics';
import { ISection } from '../../common/AttributesList/AttributesList';
import SectionHeader from '../../common/AttributesList/SectionHeader';

interface IProps {
  isRefreshing?: boolean;
  customData?: { data: PlaylistStats; isLoading: boolean; error?: string };
}

const GuestsAttribute: React.FC<IProps> = ({ isRefreshing, customData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const sharedWithFilter = useSelector(playlistSharedWithFilterSelector);
  const displayMode = useSelector(playlistDisplayModeSelector);
  const isGuestsAttributeLoaded = useSelector(isGuestsAttributeLoadedSelector);
  const guestsAttribute = useSelector(guestsAttributeSelector);
  const { data, isLoading, error } = customData || guestsAttribute;

  const handleLoad = useCallback(() => {
    dispatch(
      log({
        event: 'GuestsAttribute.handleLoad',
      }),
    );

    dispatch(getPlaylistGuestsAttribute());
  }, [dispatch]);

  const formattedValue: PlaylistAttributeValue = useMemo(
    () => ({
      id: 1,
      attribute_title_id: 1,
      name: t('analytics.overview.guests'),
      stats: data,
    }),
    [data, t],
  );

  const formattedHeaderValue: ISection = useMemo(
    () => ({
      title: error ? t('analytics.overview.guests') : '',
      data: formattedValue ? [formattedValue] : [],
      isLoading,
      isError: !!error,
      isDisplaySkeleton: isLoading && !isRefreshing,
    }),
    [formattedValue, error, isLoading, isRefreshing, t],
  );

  useEffect(() => {
    if (!isFocused || isLoading || isGuestsAttributeLoaded) {
      return;
    }
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isGuestsAttributeLoaded]);

  if (
    !formattedValue.stats ||
    (sharedWithFilter[ESharedWithFilter.COMMUNICATION_HUB] && !sharedWithFilter[ESharedWithFilter.ORGANICALLY])
  ) {
    return null;
  }

  return (
    <S.Attribute>
      <SectionHeader section={formattedHeaderValue} onRefresh={handleLoad} skeletonRowsNum={1} />
      {(!isLoading || isRefreshing) && !error && (
        <AttributeValueItem
          displayMode={displayMode}
          item={formattedValue}
          isBoldTitle
          hasMultipleValues
          withDivider
          isGuest
        />
      )}
    </S.Attribute>
  );
};

const S = {
  Attribute: styled.View`
    margin-bottom: ${calcHeight(24)}px;
  `,
  AttributeName: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
  `,
  AttributeValue: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
};

export default GuestsAttribute;
