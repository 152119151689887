import React, { useCallback, useEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { calcFontSize, calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import BgMagicIcon from '../../../assets/icons/bg-magic-icon.svg';
import { KeyboardAwareView } from '../../../components/shared';
import { EShareItemType } from '../../../store/sharePanel/sharePanel.slice';
import { useDebouncedCallback } from 'use-debounce';

import { setHomeActiveTab } from '../../../store/homeScreen/homeScreen.slice';
import { Tabs } from '../../../store/homeScreen/homeScreen.types';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import { setPlaylistSubject, setPlaylistToEdit } from '../../../store/createPlaylist/createPlaylist.actions';
import {
  fetchPlaylistsRequest,
  patchPlaylist,
  triggerCreatedPlaylistTransactionalCommunication,
} from '../../../store/playlist/playlist.actions';
import Routes from '../../../navigation/routes';
import ShadowedContainer from '../../../components/ShadowedContainer';
import CreationAnimationScreen from '../../../components/shared/CreationAnimationScreen';
import ShareSection from '../../creationBite/common/ShareSection';
import HomeButton from '../../../components/shared/HomeButton';
import Header from '../../../components/Header';
import PlaylistBitesCard from '../../../components/playlist/PlaylistBitesCard/PlaylistBitesCard';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import { useTheme } from 'styled-components/native';
import Button from '../../../components/shared/buttons/Button/Button';
import { playlistNameInputDataSet } from './playlistCreated.constants';
import { useIsFocused } from '@react-navigation/native';
import EditIcon from '../../../assets/icons/preview/edit.svg';

const buttonStyle = {
  height: calcHeight(50),
  width: calcWidth(148),
};

const PlaylistCreated = ({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { editedPlaylist, subject, isPosting } = useSelector(createPlaylistSelector);
  const org = useSelector(activeOrganizationSelector);
  const playlist = useSelector(playlistItemSelector(editedPlaylist?.id));

  const { t } = useTranslation();
  const theme = useTheme();
  const [localInputValue, setLocalInputValue] = useState(subject);

  const previewOnEdit = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'edit_playlist_btn',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
    navigation.replace(Routes.EditPlaylistStack.StackName, {
      screen: Routes.EditPlaylistStack.PlaylistSettings,
      params: { playlistId: editedPlaylist?.id },
    });
    dispatch(setPlaylistToEdit({ playlist }));
  }, [editedPlaylist?.id, dispatch, navigation, playlist]);

  const updatePlaylistName = useDebouncedCallback((value: string) => {
    dispatch(patchPlaylist(editedPlaylist?.id, { subject: value }));
  }, 300);

  const clearName = useCallback(() => {
    setLocalInputValue('');
  }, []);

  const resetName = useCallback(() => {
    setLocalInputValue(subject);
  }, [subject]);

  const onChangeText = useCallback(
    (value) => {
      if (value.length <= 40 && value !== subject) {
        dispatch(
          trackEvent({
            event: 'rename_playlist',
            props: {
              playlist_id: editedPlaylist?.id,
            },
          }),
        );
        setLocalInputValue(value);
        dispatch(setPlaylistSubject(value));
        updatePlaylistName.callback(value);
      }
    },
    [subject, editedPlaylist?.id, dispatch, updatePlaylistName],
  );

  const skipHandler = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'back_to_my_feed',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
    dispatch(setHomeActiveTab(Tabs.FEED));
    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [dispatch, editedPlaylist?.id, navigation]);

  useEffect(() => {
    if (!isPosting) {
      setLocalInputValue(subject);
    }
  }, [dispatch, org.id, isPosting, subject]);

  useEffect(() => {
    if (!isPosting) {
      dispatch(fetchPlaylistsRequest(org.id));
    }
  }, [dispatch, org.id, isPosting]);

  useEffect(() => {
    if (isFocused && !isPosting && editedPlaylist) {
      dispatch(triggerCreatedPlaylistTransactionalCommunication({ playlistId: editedPlaylist.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPosting, isFocused]);

  const renderHeaderTitle = useCallback(() => <S.Title>{t('playlistCreated.title')}</S.Title>, [t]);

  const renderHeaderLeft = useCallback(() => <HomeButton />, []);

  const renderHeaderRight = useCallback(
    () => (
      <ShadowedContainer distance={25}>
        <S.EditButton onPress={previewOnEdit}>
          <EditIcon width='28' height='28' color={theme.colors.primaryBlue} />
        </S.EditButton>
      </ShadowedContainer>
    ),
    [previewOnEdit, theme.colors.primaryBlue],
  );

  const handleOpenPreview = useCallback(() => {
    navigation.navigate(Routes.PreviewStack.StackName, {
      screen: Routes.PreviewStack.PreviewPlaylist,
      params: {
        playlistId: editedPlaylist?.id,
      },
    });
  }, [editedPlaylist, navigation]);

  const renderLeftComponent = useCallback(() => {
    return (
      <Button
        isShadowed
        onPress={handleOpenPreview}
        disabled={!editedPlaylist?.id}
        text={t('common.Preview')}
        style={buttonStyle}
        fill={theme.colors.white}
        border={theme.colors.primaryBlue}
        textColor={theme.colors.primaryBlue}
      />
    );
  }, [editedPlaylist?.id, handleOpenPreview, t, theme.colors.primaryBlue, theme.colors.white]);

  if (isPosting) {
    return <CreationAnimationScreen loadingText={t('playlistCreated.animationScreenText')} />;
  }

  return (
    <>
      <S.Container keyboardShouldPersistTaps='handled'>
        <>
          <BgMagicIcon style={S.BgMagicIconStyles} />
          <Header headerLeft={renderHeaderLeft} headerRight={renderHeaderRight} title={renderHeaderTitle} />
          <PlaylistBitesCard />
          <S.NameInput
            onFocus={clearName}
            onBlur={resetName}
            onChangeText={onChangeText}
            value={localInputValue}
            //@ts-ignore
            dataSet={playlistNameInputDataSet}
          />
          {!!editedPlaylist?.id && (
            <ShareSection
              subject={subject}
              shareData={editedPlaylist}
              shareItemType={EShareItemType.PLAYLIST}
              from='playlist_created'
              leftButtonComponent={renderLeftComponent}
            />
          )}
          <S.SkipButton onPress={skipHandler}>
            <S.SkipText>{t('playlistCreated.backToMyFeed')}</S.SkipText>
          </S.SkipButton>
        </>
      </S.Container>
    </>
  );
};

const Control = styled.View`
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const S = {
  EmptyView: styled.View`
    height: ${calcHeight(28)}px;
  `,
  Header: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${calcHeight(isWeb ? -12 : 47)}px ${calcWidth(17)}px ${calcHeight(20)}px ${calcWidth(24)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s22};
    font-weight: normal;
    max-width: ${deviceWidth - calcWidth(60) * 2}px;
    white-space: break-spaces;
    text-align: center;
  `,
  Container: styled(KeyboardAwareView)``,
  NameInput: styled.TextInput<{ editable?: boolean }>`
    z-index: 1;
    align-self: center;
    margin: ${calcHeight(23)}px 0 ${calcHeight(14)}px;
    padding: ${calcHeight(12)}px ${calcWidth(22)}px;
    width: ${calcWidth(325)}px;
    height: ${calcHeight(49)}px;
    border-radius: ${calcWidth(30)}px;
    font-size: ${calcFontSize(22)}px;
    color: ${({ theme }) => theme.colors.lightGray6};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    ${({ editable }) => css`
      border: 2px solid ${editable ? '#1253fa' : '#C0C0C0'};
    `};
  `,

  ShareButton: styled.TouchableOpacity<{ disabled: boolean }>`
    width: ${calcWidth(325)}px;
    height: ${calcHeight(50)}px;

    border-radius: ${calcHeight(50 / 2)}px;
    align-self: center;
    align-items: center;
    justify-content: center;
    ${({ disabled, theme }) => css`
      background: ${!disabled ? theme.colors.primaryBlue : '#C0C0C0'};
    `};
  `,
  Preview: styled(Control)`
    position: absolute;
    top: 0px;
    left: 0px;
    justify-content: space-between;
    padding: ${calcHeight(12)}px ${calcHeight(22)}px ${calcHeight(16)}px;
    border-bottom-right-radius: 32px;
  `,
  PreviewButton: styled.TouchableOpacity`
    background: rgba(0, 0, 0, 0.5);
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
    padding: ${calcHeight(10)}px;
    height: ${calcHeight(42)}px;
    border-radius: ${calcHeight(42 / 2)}px;
    width: ${calcWidth(185)}px;
    margin-bottom: ${isWeb ? calcHeight(198) : calcHeight(158)}px;
  `,
  ShareButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: bold;
  `,
  ShareModeContainer: styled.View`
    max-width: ${calcWidth(300)}px;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${calcHeight(27)}px;
    align-items: center;
  `,
  ShareModeText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
  `,
  ShareModeButtonText: styled.Text`
    margin: 0 ${calcWidth(16)}px;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SkipButton: styled.TouchableOpacity`
    align-self: center;
    margin-top: ${calcHeight(23)}px;
  `,
  SkipText: styled.Text`
    text-align: center;
    color: ${({ theme }) => theme.colors.gray11};
  `,
  BiteImagePlaceholder: styled.Image`
    resize-mode: contain;
    width: 40%;
    height: 50%;
  `,
  EditButton: styled.TouchableOpacity`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    border-radius: ${calcWidth(40 / 2)}px;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
  `,
  PreviewButtonText: styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: bold;
  `,
  BgMagicIconStyles: {
    position: 'absolute',
    alignSelf: 'center',
    height: isWeb ? calcHeight(846) : calcHeight(445),
    width: calcWidth(deviceWidth),
    opacity: 0.4,
  } as StyleProp<ViewStyle>,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.SFProRegular};
  `,
};

export default PlaylistCreated;
