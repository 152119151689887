import { IPlaylist } from '../../../store/playlist/playlist.types';
import { useSelector } from 'react-redux';
import { bitesStateMapSelector } from '../../../store/bite/bite.selectors';
import { useMemo } from 'react';

const usePlaylistBitesState = (playlist: IPlaylist) => {
  const bitesLoadingStateMap = useSelector(bitesStateMapSelector);

  return useMemo(() => {
    let isLoading = false;
    let isError = false;

    for (const biteShare of playlist?.bite_shares) {
      if (!isLoading && bitesLoadingStateMap[biteShare.bite]?.isLoading) {
        isLoading = true;
      }

      if (!isError && bitesLoadingStateMap[biteShare.bite]?.error) {
        isError = true;
      }

      if (isLoading && isError) {
        break;
      }
    }

    return { isBitesLoading: isLoading, isBitesError: isError };
  }, [bitesLoadingStateMap, playlist?.bite_shares]);
};

export default usePlaylistBitesState;
