import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import FilterBottomSheet from '../../common/FilterPanel/FilterBottomSheet';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { getIsRtl, getIsWebRtl } from '../../../../locale/i18n';
import EraserIcon from '../../../../assets/icons/analytics/eraser.svg';
import { resetAnalytics } from '../../../../store/analytics/analytics.slice';
import { clearFiltersButtonDataSet } from '../../analytics.constants';
import { IOrganizationAttribute } from '../../../../types/anayltics';
import FilterListItem from '../../common/FilterPanel/FilterListItem';
import {
  playlistInitialAttributesMapByIdSelector,
  playlistOrganizationAttributesSelector,
  filterAttributeByIdSelector,
  searchDataSelector,
  searchValueSelector,
  searchValuesErrorSelector,
  searchValuesLoadingSelector,
  searchValuesNextSelector,
  selectedFilterAttributeValueIdsSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import {
  getFilterList,
  getSearchList,
  resetSearchValues,
  setFilterSearchValue,
  setSelectedFilterAttributeValues,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';

const HIT_SLOP = {
  top: 10,
  left: 10,
  bottom: 10,
  right: 10,
};

interface IProps {}

const PlaylistAttributesFilter: React.FC<IProps> = () => {
  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();
  const dispatch = useDispatch();

  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>(null);

  const searchValue = useSelector(searchValueSelector);

  const initialAttributesMap = useSelector(playlistInitialAttributesMapByIdSelector);
  const isSearchDataLoading = useSelector(searchValuesLoadingSelector);
  const searchData = useSelector(searchDataSelector);
  const searchDataError = useSelector(searchValuesErrorSelector);
  const searchDataNext = useSelector(searchValuesNextSelector);
  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const attributes: IOrganizationAttribute[] = useSelector(playlistOrganizationAttributesSelector);
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);

  const attributeMapItem = useSelector(filterAttributeByIdSelector(selectedAttributeId));

  const handleGetSearchList = useCallback(
    (props) => {
      dispatch(getSearchList(props));
    },
    [dispatch],
  );

  const handleGetFilterList = useCallback(
    (props) => {
      dispatch(getFilterList(props));
    },
    [dispatch],
  );

  const handleSetSearchValue = useCallback(
    (value) => {
      dispatch(setFilterSearchValue(value));
    },
    [dispatch],
  );

  const handleDoneButtonPress = useCallback(
    (props) => {
      dispatch(setSelectedFilterAttributeValues(props));
    },
    [dispatch],
  );

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const handleFilterPress = useCallback(
    (item) => {
      dispatch(
        log({
          event: 'AttributesFilter.handleFilterPress',
          data: { item },
        }),
      );

      dispatch(getFilterList({ attributeId: item.id }));
      setIsFilterPanelVisible(true);
      setSelectedAttributeId(item.id);
    },
    [dispatch],
  );

  const handleFilterPanelClose = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesFilter.handleFilterPanelClose',
      }),
    );
    setIsFilterPanelVisible(false);
    dispatch(resetSearchValues());
  }, [dispatch]);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <FilterListItem
          initialAttributesMap={initialAttributesMap}
          isRtl={isRtl}
          isWebRtl={isWebRtl}
          item={item}
          onPress={handleFilterPress}
        />
      );
    },
    [handleFilterPress, initialAttributesMap, isRtl, isWebRtl],
  );

  const handleFilterClear = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesFilter.handlePressClearFilters',
      }),
    );

    dispatch(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
          biteAttributes: ['sharedWithFilter'],
          playlistAttributes: ['sharedWithFilter'],
          playlistStats: ['playlistQuestionNumber'],
        },
        withFiltersReset: true,
      }),
    );
  }, [dispatch]);

  if (attributes.length === 0) {
    return null;
  }

  return (
    <>
      <S.FilterListContainer>
        <S.FilterList
          keyExtractor={keyExtractor}
          isRtl={isWebRtl}
          showsHorizontalScrollIndicator={false}
          data={attributes}
          renderItem={renderItem}
          horizontal
        />
        {!!selectedAttributes?.length && !!attributes?.length && (
          <S.EraserContainer>
            <S.EraserButtonBorder />
            <S.EraserButtonContainer>
              <S.EraserButton dataSet={clearFiltersButtonDataSet} hitSlop={HIT_SLOP} onPress={handleFilterClear}>
                <EraserIcon />
              </S.EraserButton>
            </S.EraserButtonContainer>
          </S.EraserContainer>
        )}
      </S.FilterListContainer>
      <FilterBottomSheet
        setSearchValue={handleSetSearchValue}
        onGetFilterList={handleGetFilterList}
        isSearchDataLoading={isSearchDataLoading}
        onGetSearchList={handleGetSearchList}
        searchDataNext={searchDataNext}
        searchValue={searchValue}
        searchData={searchData}
        searchDataError={searchDataError}
        attributeMapItem={attributeMapItem}
        selectedValueIds={selectedValueIds}
        onDoneButtonPress={handleDoneButtonPress}
        isVisible={isFilterPanelVisible}
        onClose={handleFilterPanelClose}
        selectedAttributeId={selectedAttributeId}
      />
    </>
  );
};

const S = {
  FilterListContainer: styled.View`
    width: ${isWeb ? calcWidth(550) : deviceWidth}px;
    align-self: center;
    display: flex;
    flex-direction: row;

    border: 0px solid ${({ theme }) => theme.colors.overviewDivider};
    border-bottom-width: 1px;
  `,
  FilterList: styled.FlatList<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    max-height: ${calcHeight(24)}px;

    padding: 0 ${calcWidth(19)}px;
    margin-bottom: ${calcHeight(10)}px;
  `,

  EraserButtonContainer: styled.View`
    background: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
  `,
  EraserButtonBorder: styled.View`
    width: 3px;
    background: ${({ theme }) => theme.colors.overviewDividerTransparent};
    border: 0 solid ${({ theme }) => theme.colors.overviewDivider};
    border-right-width: 1px;
  `,
  EraserContainer: styled.View`
    display: flex;
    flex-direction: row;
    right: 0;
    height: 28px;
  `,
  EraserButton: styled.TouchableOpacity`
    margin-left: ${calcWidth(10)}px;
    width: ${calcWidth(30)}px;
  `,
};

export default memo(PlaylistAttributesFilter);
