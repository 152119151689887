import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { Divider } from '../../../components/shared';
import ArrowDown from '../../../assets/icons/feed/arrow-down.svg';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Routes from '../../../navigation/routes';
import Skeleton from '../../../components/shared/Skeleton';
import { useTranslation } from 'react-i18next';
import Error from '../common/Error';
import {
  organizationAttributesErrorSelector,
  organizationAttributesLoadingSelector,
} from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { contentIdSelector, hasDistributionsSelector } from '../../../store/analytics/analytics.selector';
import { log } from '../../../store/appActivity/appActivity.slice';
import { getIsRtl, getIsWebRtl } from '../../../locale/i18n';
import { StackNavigationProp } from '@react-navigation/stack';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import {
  getPlaylistQuestions,
  getPlaylistStatsOverview,
} from '../../../store/attributes/playlistStats/playlistStats.slice';
import {
  playlistQuestionNumberSelector,
  playlistStatsOverviewErrorSelector,
  playlistStatsOverviewLoadingSelector,
  playlistStatsOverviewSelector,
} from '../../../store/attributes/playlistStats/playlistStats.selector';
import { getPlaylistOrganizationAttributes } from '../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import useCalculatePlaylistStats from '../../analytics/playlist/hooks/useCalculatePlaylistStats';
import usePlaylistBitesState from '../hooks/usePlaylistBitesState';
import { LTR_MARK } from '../../../utils/constants/common';

interface IProps {
  isRefreshing: boolean;
}

const PlaylistStatsOverview: React.FC<IProps> = ({ isRefreshing }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const isFocused = useIsFocused();

  const organizationAttributesError = useSelector(organizationAttributesErrorSelector);
  const playlistStatsOverview = useSelector(playlistStatsOverviewSelector);
  const error = useSelector(playlistStatsOverviewErrorSelector);
  const isStatsLoading = useSelector(playlistStatsOverviewLoadingSelector);
  const playlistQuestionNumber = useSelector(playlistQuestionNumberSelector);

  const isAttributesLoading = useSelector(organizationAttributesLoadingSelector);
  const hasDistributions = useSelector(hasDistributionsSelector);

  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();
  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const { isBitesLoading, isBitesError } = usePlaylistBitesState(playlist);

  const isLoading = isStatsLoading || isAttributesLoading || isBitesLoading || playlistQuestionNumber === null;

  const isCanProceedToDetails =
    playlist &&
    !(isAttributesLoading || isBitesLoading || playlistQuestionNumber === null) &&
    hasDistributions !== null;

  const statsOverview = useCalculatePlaylistStats(playlistStatsOverview);

  const handleAnalyticsPress = useCallback(() => {
    if (!isCanProceedToDetails) {
      return;
    }

    dispatch(
      log({
        event: 'PlaylistStatsOverview.handleAnalyticsPress',
        data: { isCanProceedToOverview: isCanProceedToDetails },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.PlaylistStats);
  }, [dispatch, isCanProceedToDetails, navigation]);

  // const handleAnalysisPress = useCallback(() => {
  //   if (!isCanProceedToDetails) {
  //     return;
  //   }
  //
  //   dispatch(
  //     log({
  //       event: 'PlaylistStatsOverview.handleAnalysisPress',
  //       data: { isCanProceedToOverview: isCanProceedToDetails },
  //     }),
  //   );
  //
  //   navigation.navigate(Routes.AnalyticsStack.PlaylistAnalysis);
  // }, [dispatch, isCanProceedToDetails, navigation]);

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'PlaylistStatsOverview.handleRefresh',
      }),
    );

    if (organizationAttributesError) {
      dispatch(getPlaylistOrganizationAttributes({ organizationId: playlist.organization }));
    }

    dispatch(getPlaylistQuestions());
    dispatch(getPlaylistStatsOverview());
  }, [dispatch, organizationAttributesError, playlist]);

  useEffect(() => {
    if (!isFocused || isRefreshing) {
      return;
    }

    if (!isStatsLoading && playlistStatsOverview === null) {
      dispatch(getPlaylistQuestions());
      dispatch(getPlaylistStatsOverview());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused, isRefreshing]);

  return (
    <S.Container>
      {organizationAttributesError || error || isBitesError ? (
        <Error onRefresh={handleRefresh} />
      ) : (
        <S.Content>
          <S.NumberTitle isRtl={isWebRtl} onPress={handleAnalyticsPress}>
            <S.Title>{t('playlistScreen.playlistStatsOverview.title')}</S.Title>
            {isCanProceedToDetails && (
              <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>
                <ArrowDown color={theme.colors.primaryBlue} />
              </S.ArrowContainer>
            )}
          </S.NumberTitle>
          <S.Divider />
          <S.StatsContainer isRtl={isWebRtl} onPress={handleAnalyticsPress}>
            <S.Text>{t('playlistScreen.playlistStatsOverview.started')}</S.Text>
            {(isLoading && !isRefreshing) || hasDistributions === null ? (
              <Skeleton width={40} height={20} borderRadius={4} />
            ) : hasDistributions ? (
              <S.NumberContainer isRtl={isWebRtl}>
                <S.NumberText>
                  {LTR_MARK}
                  {statsOverview.started} / {statsOverview.sharedWithUsersNum}
                </S.NumberText>
                <S.NumberText bold>{statsOverview.startedRate}%</S.NumberText>
              </S.NumberContainer>
            ) : (
              <S.NumberContainer>
                <S.NumberText bold>{statsOverview.started}</S.NumberText>
              </S.NumberContainer>
            )}
          </S.StatsContainer>
          <S.StatsContainer isRtl={isWebRtl} onPress={handleAnalyticsPress}>
            <S.Text>{t('playlistScreen.playlistStatsOverview.progress')}</S.Text>
            {(isLoading && !isRefreshing) || hasDistributions === null ? (
              <Skeleton width={40} height={20} borderRadius={4} />
            ) : (
              <S.NumberContainer>
                <S.NumberText bold>{statsOverview.progressRate}%</S.NumberText>
              </S.NumberContainer>
            )}
          </S.StatsContainer>
          <S.StatsContainer isRtl={isWebRtl} onPress={handleAnalyticsPress}>
            <S.Text>{t('playlistScreen.playlistStatsOverview.completed')}</S.Text>
            {(isLoading && !isRefreshing) || hasDistributions === null ? (
              <Skeleton width={40} height={20} borderRadius={4} />
            ) : (
              <S.NumberContainer isRtl={isWebRtl}>
                <S.NumberText>
                  {LTR_MARK}
                  {statsOverview.completed} / {statsOverview.started}
                </S.NumberText>
                <S.NumberText bold>{statsOverview.completedRate}%</S.NumberText>
              </S.NumberContainer>
            )}
          </S.StatsContainer>
          <S.StatsContainer isRtl={isWebRtl} onPress={handleAnalyticsPress}>
            <S.Text>{t('playlistScreen.playlistStatsOverview.success')}</S.Text>
            {(isLoading && !isRefreshing) || hasDistributions === null ? (
              <Skeleton width={40} height={20} borderRadius={4} />
            ) : (
              <S.NumberContainer>
                <S.NumberText bold>{playlistQuestionNumber ? `${statsOverview.success}%` : '-'}</S.NumberText>
              </S.NumberContainer>
            )}
          </S.StatsContainer>
          {/*<S.Divider />*/}
          {/*<S.NumberTitle isRtl={isWebRtl} onPress={handleAnalysisPress}>*/}
          {/*  <S.Title>{t('playlistScreen.playlistAnalysis')}</S.Title>*/}
          {/*  {isCanProceedToDetails && (*/}
          {/*    <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>*/}
          {/*      <ArrowDown color={theme.colors.primaryBlue} />*/}
          {/*    </S.ArrowContainer>*/}
          {/*  )}*/}
          {/*</S.NumberTitle>*/}
        </S.Content>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    margin-top: ${calcHeight(17)}px;
    width: ${calcWidth(351)}px;
    background: ${({ theme }) => theme.colors.overviewBackground};
    border-radius: 20px;
  `,
  Content: styled.View`
    width: 100%;
    padding: ${calcHeight(20)}px ${calcWidth(15)}px;
  `,
  Title: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 700;
  `,
  Text: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-style: normal;
    font-weight: 400;
    margin-left: ${calcWidth(10)}px;
  `,
  NumberText: styled.Text<{ isRtl?: boolean; bold?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-style: normal;
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    margin: 0 ${calcWidth(5)}px;
  `,
  NumberTitle: styled.TouchableOpacity<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    margin: ${calcHeight(5)}px 0 ${calcHeight(5)}px ${calcWidth(10)}px;
  `,
  StatsContainer: styled.TouchableOpacity<{ isRtl: boolean }>`
    width: 100%;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    margin: ${calcHeight(5)}px 0;
  `,
  NumberContainer: styled.View<{ isRtl?: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: flex-end;
    margin: 0 ${calcWidth(20)}px;
    height: ${calcHeight(28)}px;
  `,
  Divider: styled(Divider)`
    background-color: ${({ theme }) => theme.colors.overviewDivider};
    margin: ${calcHeight(15)}px 0;
  `,
  ArrowContainer: styled.View<{ isWebRtl: boolean; isRtl: boolean }>`
    position: absolute;
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;

    transform: ${({ isRtl }) => (isRtl ? 'rotate(90deg)' : 'rotate(-90deg)')};
    ${({ isWebRtl }) =>
      isWebRtl
        ? css`
            left: ${calcWidth(0)}px;
          `
        : css`
            right: ${calcWidth(0)}px;
          `};
  `,
  RefreshButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(10)}px;
  `,
  RefreshButtonText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,
};

export default memo(PlaylistStatsOverview);
