import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import { SITE_URL } from '../../utils/constants/urls';
import { isWeb } from '../../utils/dimensions';
import { LinkParams } from './types';
import openShareLinkForNative from './common/openShareLinkForNative';
import { SAML_METADATA_URL } from '../../utils/constants';

const useOpenSharePlaylistLink = () => {
  const { t } = useTranslation();

  const openSharePlaylistLink = async ({ playlist, subject, afterShareAction = undefined, isBeekeeperOrg }) => {
    const { link, copyTitle, copyText } = getOpenSharePlaylistLinkParams({
      playlist,
      subject,
      t,
      isBeekeeperOrg,
    });

    if (isWeb) {
      openSharePlaylistLinkForWeb({
        link,
        t,
        isQuiz: playlist.is_quiz,
      });
    } else {
      await openShareLinkForNative({ copyTitle, copyText, afterShareAction });
    }
  };

  return openSharePlaylistLink;
};

export default useOpenSharePlaylistLink;

export const getOpenSharePlaylistLinkParams = ({ playlist, subject, t, isBeekeeperOrg }): LinkParams => {
  const defaultLink = `${SITE_URL}${playlist.is_quiz ? '/quiz/' : '/playlists/'}${playlist.id}?timestamp=${Date.now()}`;
  const link = isBeekeeperOrg
    ? `${isBeekeeperOrg.authUrl}?entity_id=${SAML_METADATA_URL}&target=${defaultLink}`
    : defaultLink;
  const copyTitle = playlist.is_quiz ? t('openShareLink.quizCopyTitle') : t('openShareLink.playlistCopyTitle');
  const copyText = `${subject}\n${link}`;
  return { link, copyTitle, copyText };
};

const openSharePlaylistLinkForWeb = ({ link, t, isQuiz }) => {
  const toastText = isQuiz ? t('openShareLink.quizToast') : t('openShareLink.playlistToast');
  toast(toastText);
  copy(`${link}`);
};
