import { all, call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects';
import Types, {
  EIntroMediaProcessingStatus,
  IIntroMediaProcessingStatus,
  ITrackingMeta,
  ITrackingOptions,
  ITrackIntroMediaProcessing,
} from './createBite/createBite.types';
import { createBiteSelector, introMediaProcessingStatusSelector } from './createBite/createBite.selectors';
import { ICreateBiteState } from './createBite/createBite.reducer';
import { isBiteQuestionCreatedSelector } from './biteQuestion/biteQuestion.selectors';
import { isBiteSummaryCreatedSelector } from './biteSummary/biteSummary.selectors';
import * as calls from './api/bites-api/calls/bite.calls';
import { updateBiteData } from './api/bites-api/calls/bite.calls';
import { biteSelector } from './bite/bite.selectors';
import BiteTypes from './bite/bite.types';
import { indexBites, setBites, setSelectedBiteName } from './bite/bite.actions';
import {
  setCoverKeywordSuggestion,
  setBiteName,
  setIntroEnhancements,
  setIntroMediaProcessingStatus,
  setIntroTask,
  setIsNameSuggestionApplied,
  setSummarySuggestionCards,
  setQuestionSuggestion,
  setIntroSubtitles,
} from './createBite/createBites.actions';
import { EEnhanceType, IBiteItem } from '../types/bite';
import { log, logError, trackEvent } from './appActivity/appActivity.slice';
import { userSelector } from '../store/auth/auth.selectors';
import getIntroProcessingStatusByUploadStatus from '../utils/introMedia/getIntroProcessingStatusByUploadStatus';
import { IAction } from './common/types';
import { navigationRef } from '../navigation/RootNavigation';
import { fetchFullBitesSaga, indexBitesSaga } from './bite/bite.saga';
import Routes from '../navigation/routes';
import searchPexels from '../services/pexels';
import { deepClone } from '@datadog/browser-core';
import { ISubtitles } from '../types/subtitles';
import { translateVoiceoversByConfigSaga } from './createBite/createBite.saga';

const POLLING_INTERVAL = 1000;
const ORIGINAL_PROCESSING_TIMEOUT = 2 * 60 * 1000;
const ENHANCEMENTS_PROCESSING_TIMEOUT = 5 * 60 * 1000;
export const SUBTITLES_PROCESSING_TIMEOUT = 90 * 1000;

function* trackIntroMediaProcessingIsFinished({ trackingOptions, trackingMeta }) {
  const isNewBite = yield getIsNewBite({
    biteId: trackingMeta.biteId || null,
  });

  if (
    trackingOptions.original_processing_ts &&
    trackingOptions.enhancements_processing_ts &&
    trackingOptions.subtitles_processing_ts &&
    !trackingOptions.total_ts
  ) {
    trackingOptions.total_ts = Date.now() - trackingMeta.startTs.start;

    if (!isNewBite) {
      yield put(
        setIntroMediaProcessingStatus({
          all:
            trackingMeta.status.all === EIntroMediaProcessingStatus.ERROR
              ? EIntroMediaProcessingStatus.ERROR
              : EIntroMediaProcessingStatus.SUCCESS,
        }),
      );
    }
  }
}

function* getIntroMediaProcessIsNew({ biteId, initialTaskId, initialCounter }) {
  const { introMediaProcessingStatus, taskId }: ICreateBiteState = yield select(createBiteSelector);
  const { selectedBite } = yield select(biteSelector);

  const isNewBiteId = (biteId || selectedBite?.id) && biteId !== selectedBite?.id;

  const isNewCounter = initialCounter !== introMediaProcessingStatus.counter;
  const isNewTaskId = taskId === null || taskId !== initialTaskId;
  const isNewTask = isNewTaskId || isNewCounter;

  return isNewBiteId || isNewTask;
}

function* getIsNewBite({ biteId }) {
  const { selectedBite } = yield select(biteSelector);
  return (selectedBite?.id || null) !== biteId;
}

function* startIntroMediaProcessingSaga({ payload: { taskId } }: IAction<{ taskId: string }>) {
  const user = yield select(userSelector);
  const { selectedBite } = yield select(biteSelector);
  const { counter: initialCounter }: IIntroMediaProcessingStatus = yield select(introMediaProcessingStatusSelector);

  const trackingOptions: ITrackingOptions = {
    bites_user_id: user.id,
    bite_id: null,
    task_id: taskId,
    feature: null,
    status: null,
    s3_ts: null,
    original_processing_ts: null,
    enhancements_processing_ts: null,
    subtitles_processing_ts: null,
    total_ts: null,
  };

  const trackingMeta: ITrackingMeta = {
    biteId: selectedBite?.id,
    startTs: {
      start: Date.now(),
      s3: Date.now(),
      processing_subtitles: Date.now(),
      processing_enhancements: Date.now(),
    },
    status: {
      all: EIntroMediaProcessingStatus.PROCESSING,
      audioExport: EIntroMediaProcessingStatus.INACTIVE,
      videoExport: EIntroMediaProcessingStatus.INACTIVE,
      s3: EIntroMediaProcessingStatus.SUCCESS,
      original: EIntroMediaProcessingStatus.PROCESSING,
      enhancements: EIntroMediaProcessingStatus.PROCESSING,
      subtitles: EIntroMediaProcessingStatus.PROCESSING,
      summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
      questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
    },
  };

  yield put(
    setIntroMediaProcessingStatus({
      biteId: selectedBite?.id,
      all: EIntroMediaProcessingStatus.PROCESSING,
      audioExport: EIntroMediaProcessingStatus.INACTIVE,
      videoExport: EIntroMediaProcessingStatus.INACTIVE,
      s3: EIntroMediaProcessingStatus.SUCCESS,
      original: EIntroMediaProcessingStatus.PROCESSING,
      enhancements: EIntroMediaProcessingStatus.PROCESSING,
      subtitles: EIntroMediaProcessingStatus.PROCESSING,
      summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
      questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
    }),
  );

  yield put(setIntroTask(taskId));

  yield spawn(trackIntroMediaProcessingEnhancements, {
    initialTaskId: taskId,
    initialCounter,
    trackingOptions,
    trackingMeta,
  });
  yield spawn(trackIntroMediaProcessingSubtitles, {
    initialTaskId: taskId,
    initialCounter,
    trackingOptions,
    trackingMeta,
  });
}

export function* trackIntroMediaProcessingEnhancements({
  initialTaskId,
  initialCounter,
  trackingOptions,
  trackingMeta,
  videoMeta,
  processId,
}: ITrackIntroMediaProcessing) {
  yield put(
    log({
      event: 'trackIntroMediaProcessingEnhancements',
      processId,
    }),
  );

  let didUpdateStoreOriginal = false;
  let didUpdateStoreEnhanced = false;
  let triggeredVoiceoverTranslations = false;

  while (true) {
    try {
      const introMediaProcessIsNew = yield getIntroMediaProcessIsNew({
        biteId: trackingMeta.biteId || null,
        initialTaskId,
        initialCounter,
      });

      if (introMediaProcessIsNew) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: introMediaProcessIsNew 1',
            processId,
          }),
        );
        return;
      }

      const { data } = yield calls.getIntroMediaEnhancementsByTaskId(initialTaskId);

      const introMediaProcessIsNewAfterRequest = yield getIntroMediaProcessIsNew({
        biteId: trackingMeta.biteId || null,
        initialTaskId,
        initialCounter,
      });

      if (introMediaProcessIsNewAfterRequest) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: introMediaProcessIsNew 2',
            processId,
          }),
        );
        return;
      }

      const { selectedBite } = yield select(biteSelector);

      if (!trackingMeta.biteId) {
        trackingMeta.biteId = selectedBite?.id;
        trackingOptions.bite_id = trackingOptions.bite_id || trackingMeta.biteId || null;
      }

      const original = data.find(({ enhance_type }) => enhance_type === EEnhanceType.original);
      const enhancement = data.find(({ enhance_type }) => enhance_type === EEnhanceType.speech_enhancement);

      if (original?.s3_video_url && !triggeredVoiceoverTranslations) {
        yield spawn(translateVoiceoversByConfigSaga, {
          taskId: original.task_id,
          enhanceType: 'original',
        });
        triggeredVoiceoverTranslations = true;
      }

      const isNewBite = yield getIsNewBite({
        biteId: trackingMeta.biteId || null,
      });

      const newOriginalStatus = getIntroProcessingStatusByUploadStatus(original?.upload_status);
      if (newOriginalStatus !== trackingMeta.status.original) {
        const currentStatus = trackingMeta.status.original;

        trackingMeta.status.original = newOriginalStatus;
        trackingOptions.original_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;

        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: original status updated',
            processId,
            data: {
              enhancements: [original],
              biteId: selectedBite?.id,
              currentStatus,
              newOriginalStatus,
              isNewBite,
            },
            metrics: trackingMeta.withMetricsTracking
              ? {
                  originalVideoProcessingMs:
                    newOriginalStatus === EIntroMediaProcessingStatus.SUCCESS
                      ? trackingOptions.original_processing_ts
                      : undefined,
                  originalVideoProcessingMsPerMb:
                    newOriginalStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.size
                      ? trackingOptions.original_processing_ts / videoMeta?.size
                      : undefined,
                  originalVideoProcessingMsPerSec:
                    newOriginalStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.duration
                      ? trackingOptions.original_processing_ts / videoMeta?.duration
                      : undefined,
                }
              : undefined,
          }),
        );

        yield put(setIntroEnhancements({ enhancements: [original], biteId: selectedBite?.id }));
        didUpdateStoreOriginal = true;

        if (!isNewBite) {
          const statusUpdate: Partial<IIntroMediaProcessingStatus> = {
            // @ts-ignore
            original: newOriginalStatus,
            biteId: trackingMeta.biteId,
          };

          yield put(setIntroMediaProcessingStatus(statusUpdate));
        }

        if (newOriginalStatus === EIntroMediaProcessingStatus.ERROR) {
          trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;
          trackingMeta.status.enhancements = EIntroMediaProcessingStatus.ERROR;
          trackingOptions.enhancements_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;

          yield put(
            log({
              event: 'trackIntroMediaProcessingEnhancements: quit - original error status',
              processId,
              data: {
                isNewBite,
                trackingOptions,
                trackingMeta,
              },
              metrics: trackingMeta.withMetricsTracking
                ? {
                    originalVideoProcessingError: 1,
                  }
                : undefined,
            }),
          );
          return;
        }
      }

      if (
        !trackingOptions.original_processing_ts &&
        Date.now() - trackingMeta.startTs.processing_enhancements > ORIGINAL_PROCESSING_TIMEOUT
      ) {
        if (!isNewBite) {
          yield put(
            setIntroMediaProcessingStatus({
              original: EIntroMediaProcessingStatus.ERROR,
              enhancements: EIntroMediaProcessingStatus.ERROR,
              biteId: trackingMeta.biteId,
            }),
          );
        }

        trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;
        trackingMeta.status.original = EIntroMediaProcessingStatus.ERROR;
        trackingMeta.status.enhancements = EIntroMediaProcessingStatus.ERROR;
        trackingOptions.original_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;
        trackingOptions.enhancements_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;

        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: quit - original timeout',
            processId,
            data: {
              isNewBite,
              trackingMeta,
              trackingOptions,
            },
            metrics: trackingMeta.withMetricsTracking
              ? {
                  originalVideoProcessingTimeout: 1,
                }
              : undefined,
          }),
        );
        return;
      }

      const newEnhancementStatus = getIntroProcessingStatusByUploadStatus(enhancement?.upload_status);
      if (newEnhancementStatus !== trackingMeta.status.enhancements) {
        const currentStatus = trackingMeta.status.enhancements;

        trackingMeta.status.enhancements = newEnhancementStatus;
        trackingOptions.enhancements_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;

        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: enhancements status updated',
            processId,
            data: {
              enhancements: [enhancement],
              biteId: selectedBite?.id,
              currentStatus,
              newEnhancementStatus,
              isNewBite,
            },
            metrics: trackingMeta.withMetricsTracking
              ? {
                  enhancedVideoProcessingMs:
                    newEnhancementStatus === EIntroMediaProcessingStatus.SUCCESS
                      ? trackingOptions.enhancements_processing_ts
                      : undefined,
                  enhancedVideoProcessingMsPerMb:
                    newEnhancementStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.size
                      ? trackingOptions.enhancements_processing_ts / videoMeta?.size
                      : undefined,
                  enhancedVideoProcessingMsPerSec:
                    newEnhancementStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.duration
                      ? trackingOptions.enhancements_processing_ts / videoMeta?.duration
                      : undefined,
                }
              : undefined,
          }),
        );

        yield put(setIntroEnhancements({ enhancements: [enhancement], biteId: selectedBite?.id }));
        didUpdateStoreEnhanced = true;

        if (!isNewBite) {
          const statusUpdate: Partial<IIntroMediaProcessingStatus> = {
            enhancements: newEnhancementStatus,
            biteId: trackingMeta.biteId,
          };

          yield put(setIntroMediaProcessingStatus(statusUpdate));
        }

        if (newEnhancementStatus === EIntroMediaProcessingStatus.ERROR) {
          trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;

          yield put(
            log({
              event: 'trackIntroMediaProcessingEnhancements: quit - enhancements error status',
              processId,
              data: {
                isNewBite,
                trackingOptions,
                trackingMeta,
              },
              metrics: trackingMeta.withMetricsTracking
                ? {
                    enhancedVideoProcessingError: 1,
                  }
                : undefined,
            }),
          );
          return;
        }
      }

      if (
        !trackingOptions.enhancements_processing_ts &&
        Date.now() - trackingMeta.startTs.processing_enhancements > ENHANCEMENTS_PROCESSING_TIMEOUT
      ) {
        if (!isNewBite) {
          yield put(
            setIntroMediaProcessingStatus({
              enhancements: EIntroMediaProcessingStatus.ERROR,
              biteId: trackingMeta.biteId,
            }),
          );
        }

        trackingMeta.status.enhancements = EIntroMediaProcessingStatus.ERROR;
        trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;
        trackingOptions.enhancements_processing_ts = Date.now() - trackingMeta.startTs.processing_enhancements;

        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: quit - enhancements timeout',
            processId,
            data: {
              isNewBite,
              trackingOptions,
              trackingMeta,
            },
            metrics: trackingMeta.withMetricsTracking
              ? {
                  enhancedVideoProcessingTimeout: 1,
                }
              : undefined,
          }),
        );
        return;
      }

      if (!didUpdateStoreOriginal) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: set initial original media',
            processId,
            data: { enhancements: [original], biteId: selectedBite?.id },
          }),
        );

        yield put(setIntroEnhancements({ enhancements: [original], biteId: selectedBite?.id }));
        didUpdateStoreOriginal = true;
      }

      if (!didUpdateStoreEnhanced) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: set initial enhancement media',
            processId,
            data: { enhancements: [enhancement], biteId: selectedBite?.id },
          }),
        );

        yield put(setIntroEnhancements({ enhancements: [enhancement], biteId: selectedBite?.id }));
        didUpdateStoreEnhanced = true;
      }

      yield trackIntroMediaProcessingIsFinished({
        trackingOptions,
        trackingMeta,
      });

      if (trackingOptions.original_processing_ts && trackingOptions.enhancements_processing_ts) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingEnhancements: finished polling',
            processId,
          }),
        );

        return;
      }
    } catch (error) {
      yield put(
        logError({
          event: 'trackIntroMediaProcessingEnhancements: error',
          processId,
          error,
        }),
      );
    }
    yield delay(POLLING_INTERVAL);
  }
}

export function* trackIntroMediaProcessingSubtitles({
  initialTaskId,
  initialCounter,
  trackingOptions,
  trackingMeta,
  targetLocale,
  videoMeta,
  onError,
  processId,
}: ITrackIntroMediaProcessing) {
  yield put(
    log({
      event: 'trackIntroMediaProcessingSubtitles',
      processId,
      data: {
        initialTaskId,
        initialCounter,
        trackingOptions: deepClone(trackingOptions),
        trackingMeta: deepClone(trackingMeta),
        targetLocale,
        videoMeta: deepClone(videoMeta),
        onError: !!onError,
      },
    }),
  );

  let prevSubtitles = null;

  while (true) {
    try {
      const introMediaProcessIsNew = yield getIntroMediaProcessIsNew({
        biteId: trackingMeta.biteId || null,
        initialTaskId,
        initialCounter,
      });

      if (introMediaProcessIsNew) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: introMediaProcessIsNew 1',
            processId,
          }),
        );
        return;
      }

      const { data } = yield calls.getIntroMediaSubtitlesByTaskId(initialTaskId);

      const introMediaProcessIsNewAfterRequest = yield getIntroMediaProcessIsNew({
        biteId: trackingMeta.biteId || null,
        initialTaskId,
        initialCounter,
      });

      if (introMediaProcessIsNewAfterRequest) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: introMediaProcessIsNew 2',
            processId,
          }),
        );
        return;
      }

      const { selectedBite } = yield select(biteSelector);

      if (!trackingMeta.biteId) {
        trackingMeta.biteId = selectedBite?.id;
        trackingOptions.bite_id = trackingOptions.bite_id || trackingMeta.biteId || null;
      }

      const subtitles = targetLocale
        ? data.find(({ locale }) => locale === targetLocale)
        : data.length > 0
        ? data[0]
        : null;

      const isNewBite = yield getIsNewBite({
        biteId: trackingMeta.biteId || null,
      });

      const newStatus = getIntroProcessingStatusByUploadStatus(subtitles?.upload_status || 'in_progress');
      const withStatusTracking =
        !targetLocale &&
        (trackingMeta.withStatusUpdateOnSubtitlesFailure ||
          (newStatus !== EIntroMediaProcessingStatus.ERROR &&
            newStatus !== EIntroMediaProcessingStatus.NOT_APPLICABLE));

      if (newStatus !== trackingMeta.status.subtitles) {
        const currentStatus = trackingMeta.status.subtitles;

        trackingMeta.status.subtitles = newStatus;
        trackingOptions.subtitles_processing_ts = Date.now() - trackingMeta.startTs.processing_subtitles;

        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: status updated',
            processId,
            data: {
              isNewBite,
              currentStatus,
              newStatus,
              targetLocale,
              withStatusTracking,
            },
            metrics: trackingMeta.withMetricsTracking
              ? {
                  subtitlesProcessingMs:
                    newStatus === EIntroMediaProcessingStatus.SUCCESS
                      ? trackingOptions.subtitles_processing_ts
                      : undefined,
                  subtitlesProcessingMsPerMb:
                    newStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.size
                      ? trackingOptions.subtitles_processing_ts / videoMeta?.size
                      : undefined,
                  subtitlesProcessingMsPerSec:
                    newStatus === EIntroMediaProcessingStatus.SUCCESS && videoMeta?.duration
                      ? trackingOptions.subtitles_processing_ts / videoMeta?.duration
                      : undefined,
                  subtitlesProcessingError: newStatus === EIntroMediaProcessingStatus.ERROR ? 1 : undefined,
                }
              : undefined,
          }),
        );

        if (!isNewBite) {
          if (newStatus === EIntroMediaProcessingStatus.ERROR) {
            trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;
          }

          if (withStatusTracking) {
            const statusUpdate: Partial<IIntroMediaProcessingStatus> = {
              biteId: trackingMeta.biteId,
              subtitles: newStatus,
            };
            if (newStatus === EIntroMediaProcessingStatus.SUCCESS) {
              statusUpdate.questionSuggestion = EIntroMediaProcessingStatus.PROCESSING;
              statusUpdate.summarySuggestion = EIntroMediaProcessingStatus.PROCESSING;
              statusUpdate.biteNameSuggestion = EIntroMediaProcessingStatus.PROCESSING;
              statusUpdate.coverSuggestion = EIntroMediaProcessingStatus.PROCESSING;
            }
            Object.assign(trackingMeta.status, statusUpdate);
            yield put(setIntroMediaProcessingStatus(statusUpdate));
          }
        }
      }

      if (
        !trackingOptions.subtitles_processing_ts &&
        (trackingMeta.subtitlesProcessingTimeout
          ? // we can provide the timestamp from the outside when subtitles should timeout
            // thus we can control this value from the outside if we need to change it at some moment
            // ie. extend the time for polling
            Date.now() > trackingMeta.subtitlesProcessingTimeout
          : Date.now() - trackingMeta.startTs.processing_subtitles > SUBTITLES_PROCESSING_TIMEOUT)
      ) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: timeout',
            processId,
            metrics: trackingMeta.withMetricsTracking
              ? {
                  subtitlesProcessingTimeout: 1,
                }
              : undefined,
          }),
        );

        if (!isNewBite && !targetLocale && trackingMeta.withStatusUpdateOnSubtitlesFailure) {
          yield put(
            setIntroMediaProcessingStatus({
              subtitles: EIntroMediaProcessingStatus.ERROR,
              biteId: trackingMeta.biteId,
            }),
          );
        }

        trackingMeta.status.subtitles = EIntroMediaProcessingStatus.ERROR;
        trackingMeta.status.all = EIntroMediaProcessingStatus.ERROR;
        trackingOptions.subtitles_processing_ts = Date.now() - trackingMeta.startTs.processing_subtitles;

        if (typeof onError === 'function') {
          onError();
        }
      }

      if (!isNewBite && (!prevSubtitles || prevSubtitles.length !== data.length)) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: update subtitles',
            processId,
            data: {
              prevSubtitles,
              data,
            },
          }),
        );

        yield put(setIntroSubtitles(data));
        prevSubtitles = data;
      }

      if (withStatusTracking) {
        yield trackIntroMediaProcessingIsFinished({
          trackingOptions,
          trackingMeta,
        });
      }

      if (trackingMeta.status.subtitles === EIntroMediaProcessingStatus.ERROR) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: quit - error status',
            processId,
          }),
        );
        return;
      }

      if (trackingOptions.subtitles_processing_ts) {
        yield put(
          log({
            event: 'trackIntroMediaProcessingSubtitles: finished polling, checking suggestions',
            processId,
          }),
        );

        yield spawn(indexBitesSaga, { payload: { biteIds: [selectedBite.id] }, type: BiteTypes.INDEX_BITES });

        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (
          !trackingMeta.withSuggestions ||
          !trackingMeta.biteId ||
          !selectedBite?.id ||
          newStatus !== EIntroMediaProcessingStatus.SUCCESS ||
          Object.values(Routes.CreateBiteStack).indexOf(currentRouteName) === -1
        ) {
          yield put(
            log({
              event: 'trackIntroMediaProcessingSubtitles: skipping suggestions',
              processId,
              data: {
                withSuggestions: trackingMeta.withSuggestions,
                trackingMetaBiteId: trackingMeta.biteId,
                selectedBiteId: selectedBite?.id,
                newStatus,
                currentRouteName,
              },
            }),
          );

          const statusUpdate = {
            questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
            summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
            biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
            coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
          };
          Object.assign(trackingMeta.status, statusUpdate);
          yield put(setIntroMediaProcessingStatus(statusUpdate));

          return;
        }

        yield spawn(setNameAndCoverSuggestionsSaga, { processId, trackingMeta });
        yield spawn(setQuestionSuggestionSaga, { initialTaskId, processId, trackingMeta });
        yield spawn(setSummarySuggestionSaga, { initialTaskId, processId, trackingMeta });
        yield spawn(defineSubtitlesLocaleSaga, {
          initialTaskId,
          processId,
          subtitles,
          trackingMeta,
        });

        // exit the loop
        return;
      }
    } catch (error) {
      yield put(
        logError({
          event: 'trackIntroMediaProcessingSubtitles: error',
          processId,
          error,
        }),
      );

      // continue the polling loop
    }

    yield delay(POLLING_INTERVAL);
  }
}

function* defineSubtitlesLocaleSaga({
  initialTaskId,
  processId,
  subtitles,
  trackingMeta,
}: {
  initialTaskId: string;
  processId: string;
  subtitles: ISubtitles;
  trackingMeta: ITrackingMeta;
}) {
  try {
    if (subtitles?.locale !== null) {
      return;
    }

    let startTs = Date.now();

    yield put(
      log({
        event: 'defineSubtitlesLocaleSaga',
        processId,
      }),
    );

    const {
      data: { subtitles: newSubtitles },
    } = yield calls.defineSubtitlesLocale(initialTaskId);

    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    if (isNewBite) {
      return;
    }

    yield put(setIntroSubtitles(newSubtitles));

    yield put(
      log({
        event: 'defineSubtitlesLocaleSaga: done',
        processId,
        metrics: trackingMeta.withMetricsTracking
          ? {
              defineSubtitlesLocaleMs: Date.now() - startTs,
            }
          : undefined,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'defineSubtitlesLocaleSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );
  }
}

function* setNameAndCoverSuggestionsSaga({
  processId,
  trackingMeta,
}: {
  processId: string;
  trackingMeta: ITrackingMeta;
}) {
  let startTs = Date.now();

  try {
    yield put(
      log({
        event: 'setNameAndCoverSuggestionsSaga',
        processId,
        data: {
          processId,
          trackingMeta,
        },
      }),
    );

    const bites = yield fetchFullBitesSaga({ payload: [trackingMeta.biteId] });

    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    const metrics = {
      biteRequestTs: Date.now() - startTs,
    };

    if (bites.length !== 1) {
      const statusUpdate = {
        biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      };
      Object.assign(trackingMeta.status, statusUpdate);

      if (!isNewBite) {
        yield put(setIntroMediaProcessingStatus(statusUpdate));
      }

      yield put(
        log({
          event: 'setNameAndCoverSuggestionsSaga: bite not found',
          processId,
          data: {
            bites,
            trackingMeta,
          },
          metrics,
        }),
      );

      return;
    }

    const bite = bites[0];

    const { selectedBite } = yield select(biteSelector);
    const currentRouteName = navigationRef.current.getCurrentRoute().name;

    if (currentRouteName === Routes.CreateBiteStack.FinalScreen || isNewBite || !bite.suggestions) {
      const statusUpdate = {
        biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
        coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      };
      Object.assign(trackingMeta.status, statusUpdate);

      if (!isNewBite) {
        yield put(setIntroMediaProcessingStatus(statusUpdate));
      }

      yield put(
        log({
          event: 'setNameAndCoverSuggestionsSaga: skipping suggestions',
          processId,
          data: {
            currentRouteName,
            isNewBite,
            bite,
            statusUpdate,
            selectedBiteId: selectedBite?.id,
          },
          metrics,
        }),
      );

      return;
    }

    yield put(
      log({
        event: 'setNameAndCoverSuggestionsSaga: apply suggestions',
        processId,
        data: {
          currentRouteName,
          bite,
        },
        metrics,
      }),
    );

    yield spawn(setCoverKeywordSuggestionSaga, { bite, processId, trackingMeta });
    yield spawn(setNameSuggestionSaga, { bite, processId, trackingMeta });
  } catch (error) {
    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    const statusUpdate = {
      biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
    };
    Object.assign(trackingMeta.status, statusUpdate);

    if (!isNewBite) {
      yield put(setIntroMediaProcessingStatus(statusUpdate));
    }

    yield put(
      log({
        event: 'setNameAndCoverSuggestionsSaga: error',
        processId,
        error,
        data: {
          trackingMeta,
        },
      }),
    );
  }
}

function* setCoverKeywordSuggestionSaga({
  bite,
  processId,
  trackingMeta,
}: {
  bite: IBiteItem;
  processId: string;
  trackingMeta: ITrackingMeta;
}) {
  try {
    yield put(
      log({
        event: 'setCoverKeywordSuggestionSaga',
        processId,
        data: {
          bite,
          processId,
          trackingMeta,
        },
      }),
    );

    if (!bite.suggestions?.cover_keyword) {
      trackingMeta.status.coverSuggestion = EIntroMediaProcessingStatus.NOT_APPLICABLE;
      yield put(
        setIntroMediaProcessingStatus({
          coverSuggestion: EIntroMediaProcessingStatus.NOT_APPLICABLE,
        }),
      );

      yield put(
        log({
          event: 'setCoverKeywordSuggestionSaga: no cover suggestion',
          processId,
          data: {
            bite,
            trackingMeta,
          },
        }),
      );

      return;
    }

    const { photos } = yield call(searchPexels, bite.suggestions.cover_keyword, { resultsPerPage: 1 });

    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    const currentRouteName = navigationRef.current.getCurrentRoute().name;

    if (currentRouteName === Routes.CreateBiteStack.FinalScreen || isNewBite) {
      const { selectedBite } = yield select(biteSelector);

      trackingMeta.status.coverSuggestion = EIntroMediaProcessingStatus.INACTIVE;

      if (!isNewBite) {
        yield put(
          setIntroMediaProcessingStatus({
            coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
          }),
        );
      }

      yield put(
        log({
          event: 'setCoverKeywordSuggestionSaga: skipping cover suggestion',
          processId,
          data: {
            currentRouteName,
            isNewBite,
            newSelectedBiteId: selectedBite?.id,
            trackingMeta,
          },
        }),
      );

      return;
    }

    if (photos.length === 0) {
      trackingMeta.status.coverSuggestion = EIntroMediaProcessingStatus.NOT_APPLICABLE;
      yield put(
        setIntroMediaProcessingStatus({
          coverSuggestion: EIntroMediaProcessingStatus.NOT_APPLICABLE,
        }),
      );

      yield put(
        log({
          event: 'setCoverKeywordSuggestionSaga: no cover suggestion',
          processId,
          data: {
            bite,
            photosNum: photos.length,
            trackingMeta,
          },
        }),
      );

      return;
    }

    yield put(
      trackEvent({
        event: 'apply_cover_suggestion',
        props: { bite_id: trackingMeta.biteId },
      }),
    );

    trackingMeta.status.coverSuggestion = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        coverSuggestion: EIntroMediaProcessingStatus.SUCCESS,
      }),
    );

    yield put(setCoverKeywordSuggestion(bite.suggestions.cover_keyword));

    yield put(
      log({
        event: 'setCoverKeywordSuggestionSaga: apply cover suggestion',
        processId,
        data: {
          bite,
          photosNum: photos.length,
          trackingMeta,
        },
      }),
    );
  } catch (error) {
    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    trackingMeta.status.coverSuggestion = EIntroMediaProcessingStatus.ERROR;

    if (!isNewBite) {
      yield put(
        setIntroMediaProcessingStatus({
          coverSuggestion: EIntroMediaProcessingStatus.ERROR,
        }),
      );
    }

    yield put(
      logError({
        event: 'setCoverKeywordSuggestionSaga: error',
        processId,
        error,
        data: {
          trackingMeta,
        },
      }),
    );
  }
}

function* setNameSuggestionSaga({
  bite,
  processId,
  trackingMeta,
}: {
  bite: IBiteItem;
  processId: string;
  trackingMeta: ITrackingMeta;
}) {
  try {
    yield put(
      log({
        event: 'setNameSuggestionSaga',
        processId,
        data: {
          bite,
          processId,
          trackingMeta,
        },
      }),
    );

    if (!bite.suggestions?.name) {
      trackingMeta.status.biteNameSuggestion = EIntroMediaProcessingStatus.NOT_APPLICABLE;
      yield put(
        setIntroMediaProcessingStatus({
          biteNameSuggestion: EIntroMediaProcessingStatus.NOT_APPLICABLE,
        }),
      );

      yield put(
        log({
          event: 'setNameSuggestionSaga: no name suggestion',
          processId,
          data: {
            bite,
            trackingMeta,
          },
        }),
      );

      return;
    }

    yield put(
      trackEvent({
        event: 'apply_auto_naming',
        props: { bite_id: trackingMeta.biteId },
      }),
    );

    const { selectedBite } = yield select(biteSelector);

    yield put(setSelectedBiteName(bite.suggestions.name));
    yield put(setBiteName(bite.suggestions.name));
    yield put(setIsNameSuggestionApplied(true));
    yield put(setBites([{ ...selectedBite, subject: bite.suggestions.name }]));

    trackingMeta.status.biteNameSuggestion = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        biteNameSuggestion: EIntroMediaProcessingStatus.SUCCESS,
      }),
    );

    yield put(
      log({
        event: 'setNameSuggestionSaga: apply name suggestion',
        processId,
        data: {
          bite,
          trackingMeta,
        },
      }),
    );

    // update on server
    yield updateBiteData(selectedBite?.id, {
      subject: bite.suggestions.name,
    });

    yield put(indexBites({ biteIds: [selectedBite?.id] }));

    yield put(
      log({
        event: 'setNameSuggestionSaga: updateBiteData done',
        processId,
      }),
    );
  } catch (error) {
    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    trackingMeta.status.biteNameSuggestion = EIntroMediaProcessingStatus.ERROR;

    if (!isNewBite) {
      yield put(
        setIntroMediaProcessingStatus({
          biteNameSuggestion: EIntroMediaProcessingStatus.ERROR,
        }),
      );
    }

    yield put(
      logError({
        event: 'setNameSuggestionSaga: error',
        processId,
        error,
        data: {
          bite,
          trackingMeta,
        },
      }),
    );
  }
}

function* setSummarySuggestionSaga({
  initialTaskId,
  processId,
  trackingMeta,
}: {
  initialTaskId: string;
  processId: string;
  trackingMeta: ITrackingMeta;
}) {
  let startTs = Date.now();

  try {
    yield put(
      log({
        event: 'setSummarySuggestionSaga',
        processId,
        data: {
          initialTaskId,
          processId,
          trackingMeta,
          startTs,
        },
      }),
    );

    const { data: summarySuggestionRaw } = yield calls.getSummarySuggestion(initialTaskId);

    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    const currentRouteName = navigationRef.current.getCurrentRoute().name;
    const isBiteSummaryCreated = yield select(isBiteSummaryCreatedSelector);

    const metrics = trackingMeta.withMetricsTracking
      ? {
          summarySuggestionRequestTs: Date.now() - startTs,
        }
      : undefined;

    if (currentRouteName === Routes.CreateBiteStack.CreateSummaryNotes || isBiteSummaryCreated || isNewBite) {
      const { selectedBite } = yield select(biteSelector);

      trackingMeta.status.summarySuggestion = EIntroMediaProcessingStatus.INACTIVE;

      if (!isNewBite) {
        yield put(
          setIntroMediaProcessingStatus({
            summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
          }),
        );
      }

      yield put(
        log({
          event: 'setSummarySuggestionSaga: skipping summary suggestion',
          processId,
          data: {
            currentRouteName,
            isBiteSummaryCreated,
            isNewBite,
            selectedBiteId: selectedBite?.id,
            trackingMeta,
          },
          metrics,
        }),
      );

      return;
    }

    const summarySuggestion = summarySuggestionRaw.filter((item) => item?.trim?.());

    if (summarySuggestion.length === 0) {
      trackingMeta.status.summarySuggestion = EIntroMediaProcessingStatus.NOT_APPLICABLE;
      yield put(
        setIntroMediaProcessingStatus({
          summarySuggestion: EIntroMediaProcessingStatus.NOT_APPLICABLE,
        }),
      );

      yield put(
        log({
          event: 'setSummarySuggestionSaga: no summary suggestion',
          processId,
          data: {
            summarySuggestionRaw,
            summarySuggestion,
            trackingMeta,
          },
          metrics,
        }),
      );
      return;
    }

    yield put(
      trackEvent({
        event: 'apply_summary_suggestion',
        props: { bite_id: trackingMeta.biteId, number: summarySuggestion.length },
      }),
    );

    trackingMeta.status.summarySuggestion = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        summarySuggestion: EIntroMediaProcessingStatus.SUCCESS,
      }),
    );

    yield put(setSummarySuggestionCards(summarySuggestion));

    yield put(
      log({
        event: 'setSummarySuggestionSaga: apply summary suggestion',
        processId,
        data: {
          summarySuggestionRaw,
          summarySuggestion,
          trackingMeta,
        },
        metrics,
      }),
    );
  } catch (error) {
    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    trackingMeta.status.summarySuggestion = EIntroMediaProcessingStatus.ERROR;

    if (!isNewBite) {
      yield put(
        setIntroMediaProcessingStatus({
          summarySuggestion: EIntroMediaProcessingStatus.ERROR,
        }),
      );
    }

    yield put(
      logError({
        event: 'setSummarySuggestionSaga: error',
        processId,
        error,
        data: {
          isNewBite,
          trackingMeta,
        },
      }),
    );
  }
}

function* setQuestionSuggestionSaga({
  initialTaskId,
  processId,
  trackingMeta,
}: {
  initialTaskId: string;
  processId: string;
  trackingMeta: ITrackingMeta;
}) {
  let startTs = Date.now();

  try {
    yield put(
      log({
        event: 'setQuestionSuggestionSaga',
        processId,
        data: {
          initialTaskId,
          processId,
          trackingMeta,
          startTs,
        },
      }),
    );

    const { data: questionSuggestion } = yield calls.getQuestionSuggestion(initialTaskId);

    const { selectedBite } = yield select(biteSelector);

    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    const currentRouteName = navigationRef.current.getCurrentRoute().name;
    const isBiteQuestionCreated = yield select(isBiteQuestionCreatedSelector);

    const metrics = trackingMeta.withMetricsTracking
      ? {
          questionSuggestionRequestTs: Date.now() - startTs,
        }
      : undefined;

    if (currentRouteName === Routes.CreateBiteStack.BiteEditQuestion || isBiteQuestionCreated || isNewBite) {
      trackingMeta.status.questionSuggestion = EIntroMediaProcessingStatus.INACTIVE;

      if (!isNewBite) {
        yield put(
          setIntroMediaProcessingStatus({
            questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
          }),
        );
      }

      yield put(
        log({
          event: 'setQuestionSuggestionSaga: skipping question suggestion',
          processId,
          data: {
            currentRouteName,
            isBiteQuestionCreated,
            isNewBite,
            selectedBiteId: selectedBite?.id,
            trackingMeta,
          },
          metrics,
        }),
      );
      return;
    }

    if (questionSuggestion === undefined || Object.keys(questionSuggestion).length === 0) {
      trackingMeta.status.questionSuggestion = EIntroMediaProcessingStatus.NOT_APPLICABLE;
      yield put(
        setIntroMediaProcessingStatus({
          questionSuggestion: EIntroMediaProcessingStatus.NOT_APPLICABLE,
        }),
      );

      yield put(
        log({
          event: 'setQuestionSuggestionSaga: no question suggestion',
          processId,
          data: {
            questionSuggestion,
            selectedBiteId: selectedBite?.id,
            trackingMeta,
          },
          metrics,
        }),
      );
      return;
    }

    yield put(
      trackEvent({
        event: 'apply_question_suggestion',
        props: { bite_id: selectedBite.id },
      }),
    );

    trackingMeta.status.questionSuggestion = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        questionSuggestion: EIntroMediaProcessingStatus.SUCCESS,
      }),
    );

    yield put(setQuestionSuggestion(questionSuggestion));

    yield put(
      log({
        event: 'setQuestionSuggestionSaga: applying question suggestion',
        processId,
        data: {
          questionSuggestion,
          selectedBiteId: selectedBite?.id,
          trackingMeta,
        },
        metrics,
      }),
    );
  } catch (error) {
    const isNewBite = yield getIsNewBite({
      biteId: trackingMeta.biteId || null,
    });

    trackingMeta.status.questionSuggestion = EIntroMediaProcessingStatus.ERROR;

    if (!isNewBite) {
      yield put(
        setIntroMediaProcessingStatus({
          questionSuggestion: EIntroMediaProcessingStatus.ERROR,
        }),
      );
    }

    yield put(
      logError({
        event: 'setQuestionSuggestionSaga: error',
        error,
        processId,
        data: {
          isNewBite,
          trackingMeta,
        },
      }),
    );
  }
}

export default function* createBiteSagaWatcher() {
  yield all([takeLatest(Types.START_INTRO_MEDIA_PROCESSING, startIntroMediaProcessingSaga)]);
}
