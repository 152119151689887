import { useSelector } from 'react-redux';
import { contentIdSelector, hasDistributionsSelector } from '../../../../store/analytics/analytics.selector';
import { playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import { PlaylistStats } from '../../../../store/attributes/playlistAttributes/playlistAttributes.types';
import { safeDivide } from '../../../../utils/math';

export type TPlaylistStats = {
  started: number;
  startedRate?: number;
  progress: number;
  progressRate: number;
  completed: number;
  completedRate: number;
  success: number;
  sharedWithUsersNum: number;
  answeredCorrectlyNum: number;
  totalAnswersNum: number;
};

const useCalculatePlaylistStats = (stats: PlaylistStats): TPlaylistStats => {
  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const hasDistributions = useSelector(hasDistributionsSelector);

  if (!stats || !playlist) {
    return {
      started: 0,
      startedRate: 0,
      progress: 0,
      progressRate: 0,
      completed: 0,
      completedRate: 0,
      success: 0,
      sharedWithUsersNum: 0,
      answeredCorrectlyNum: 0,
      totalAnswersNum: 0,
    };
  }

  return {
    started: stats.started_users_num,
    startedRate: hasDistributions
      ? safeDivide(stats.started_users_num, stats.shared_with_communication_hub + stats.viewed_organically)
      : stats.started_users_num
      ? 100
      : 0,
    progress: stats.viewed_bite_shares_num,
    progressRate: safeDivide(stats.viewed_bite_shares_num, stats.started_users_num * playlist?.bite_shares.length),
    completed: stats.completed_users_num,
    completedRate: safeDivide(stats.completed_users_num, stats.started_users_num),
    success: safeDivide(stats.answered_correctly_num, stats.total_answers_num),
    sharedWithUsersNum: stats.shared_with_communication_hub + stats.viewed_organically,
    answeredCorrectlyNum: stats.answered_correctly_num,
    totalAnswersNum: stats.total_answers_num,
  };
};

export default useCalculatePlaylistStats;
