import React, { memo, useCallback } from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../../utils/dimensions';
import Divider from '../../../../../components/shared/Divider';
import { IPlaylistAnalyticsUser } from '../../../../../store/attributes/playlistStats/playlistStats.types';
import { getIsWebRtl } from '../../../../../locale/i18n';
import {
  absoluteValueTextDataSet,
  analyticsListItemDataSet,
  percentageValueTextDataSet,
} from '../../../analytics.constants';
import { useSelector } from 'react-redux';
import { playlistDisplayModeSelector } from '../../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import UserCard from '../../../common/UserCard';
import { safeDivide } from '../../../../../utils/math';
import { playlistQuestionNumberSelector } from '../../../../../store/attributes/playlistStats/playlistStats.selector';

interface IProps {
  lastActivity?: string;
  user: IPlaylistAnalyticsUser;
  withDivider?: boolean;
  stats?: {
    progress: number;
    success: number;
    progressTotal: number;
    successTotal: number;
  };
  onPress?: (data: {
    user: IPlaylistAnalyticsUser;
    stats: {
      progress: number;
      success: number;
      progressTotal: number;
      successTotal: number;
    };
  }) => void;
}

const ListItem: React.FC<IProps> = ({ user, withDivider, stats, onPress }) => {
  const displayMode = useSelector(playlistDisplayModeSelector);
  const isWebRtl = getIsWebRtl();
  const playlistQuestionNumber = useSelector(playlistQuestionNumberSelector);

  const handlePress = useCallback(() => {
    onPress?.({ user, stats });
  }, [onPress, stats, user]);

  return (
    <>
      {withDivider && <S.Divider />}
      <S.Container
        // @ts-ignore
        dataSet={analyticsListItemDataSet}
        isRtl={isWebRtl}
        activeOpacity={1}
        isPointer={!!onPress}
        onPress={handlePress}
      >
        <S.LeftBlock isRtl={isWebRtl}>
          <UserCard user={user} />
        </S.LeftBlock>
        {!!stats && (
          <S.IconsContainer isRtl={isWebRtl}>
            <S.IconContainer>
              {displayMode === 'absolute' ? (
                <>
                  <S.ValueText dataSet={absoluteValueTextDataSet}>
                    {stats.progress} / {stats.progressTotal}
                  </S.ValueText>
                </>
              ) : (
                <S.ValueText dataSet={percentageValueTextDataSet}>
                  {`${Math.round((stats.progress / stats.progressTotal) * 100)}%`}
                </S.ValueText>
              )}
            </S.IconContainer>
            <S.IconContainer>
              <S.ValueText>
                {playlistQuestionNumber
                  ? displayMode === 'absolute'
                    ? `${stats.success} / ${stats.successTotal}`
                    : `${safeDivide(stats.success, stats.successTotal)}%`
                  : '-'}
              </S.ValueText>
            </S.IconContainer>
          </S.IconsContainer>
        )}
      </S.Container>
    </>
  );
};

const S = {
  Container: styled.TouchableOpacity<{ isRtl: boolean; isPointer: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    margin: ${calcHeight(8)}px ${calcWidth(20)}px;
    justify-content: space-between;
    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
  `,
  LeftBlock: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  Divider: styled(Divider)`
    margin: ${calcHeight(8)}px 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(50)}px;
    margin-left: ${calcWidth(20)}px;
  `,
  ValueText: styled.Text<{ isRtl?: boolean; bold?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  `,
};

export default memo(ListItem);
