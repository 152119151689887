import Header from '../../../components/Header';
import React, { memo, useCallback } from 'react';
import styled from 'styled-components/native';
import { calcWidth, isWeb } from '../../../utils/dimensions';
import { useDispatch } from 'react-redux';
import RefreshButton from '../../../components/shared/RefreshButton';
import { resetAnalytics } from '../../../store/analytics/analytics.slice';
import { log } from '../../../store/appActivity/appActivity.slice';
import FilterMenu from './FilterMenu';
import { HAS_DYNAMIC_ISLAND } from '../../../utils/constants/env';
import { webRefreshButtonDataSet } from '../analytics.constants';
import { ESharedWithFilter } from '../../../types/anayltics';

interface IProps {
  title: string;
  subtitle: string;
  onPress?: () => void;
  sharedWithFilter: ESharedWithFilter[];
}

const AnalyticsHeader: React.FC<IProps> = ({ title, subtitle, onPress, sharedWithFilter }) => {
  const dispatch = useDispatch();

  const renderTitle = useCallback(() => {
    return (
      <S.TitleContainer activeOpacity={1} onPress={onPress}>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.TitleContainer>
    );
  }, [onPress, subtitle, title]);

  const handleRefreshAll = useCallback(() => {
    dispatch(
      log({
        event: 'AnalyticsHeader.handleRefreshAll',
      }),
    );

    dispatch(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
          playlistStats: ['playlistQuestionNumber'],
        },
      }),
    );
  }, [dispatch]);

  const renderRightButton = useCallback(() => {
    if (isWeb) {
      return (
        <S.HeaderRightContainer>
          <FilterMenu sharedWithFilter={sharedWithFilter} />
          <RefreshButton
            // @ts-ignore
            dataSet={webRefreshButtonDataSet}
            onPress={handleRefreshAll}
          />
        </S.HeaderRightContainer>
      );
    }

    return <FilterMenu sharedWithFilter={sharedWithFilter} />;
  }, [handleRefreshAll, sharedWithFilter]);

  return <Header title={renderTitle} headerRight={renderRightButton} />;
};

const S = {
  TitleContainer: styled.TouchableOpacity`
    margin-top: ${HAS_DYNAMIC_ISLAND ? 0 : 20}px;
    margin-bottom: ${HAS_DYNAMIC_ISLAND ? 0 : 10}px;
    align-items: center;
    justify-content: center;
  `,
  Title: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
  `,
  Subtitle: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    color: ${({ theme }) => theme.colors.text};
  `,
  EmptyView: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
  HeaderRightContainer: styled.View`
    position: relative;
    flex-direction: row;
    align-items: center;
  `,
};

export default memo(AnalyticsHeader);
