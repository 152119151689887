import React, { memo, useCallback, useMemo } from 'react';
import { FlatList, SafeAreaView } from 'react-native';
import AnimatedBottomSheet from '../../../../components/shared/AnimatedBottomSheet/AnimatedBottomSheet';
import styled from 'styled-components/native';
import UserCard from '../../common/UserCard';
import ProgressIcon from '../../../../assets/icons/analytics/progress.svg';
import SuccessIcon from '../../../../assets/icons/analytics/success.svg';
import { calcHeight, calcWidth, deviceHeight } from '../../../../utils/dimensions';
import { getIsWebRtl } from '../../../../locale/i18n';
import { useSelector } from 'react-redux';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import CheckMarkIcon from '../../../../assets/icons/analytics/check-mark.svg';
import CrossMarkIcon from '../../../../assets/icons/analytics/cross-mark.svg';
import MinusIcon from '../../../../assets/icons/analytics/minus.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import { PlaylistUserStats } from '../../../../store/attributes/playlistStats/playlistStats.types';
import { IPlaylist } from '../../../../store/playlist/playlist.types';
import { safeDivide } from '../../../../utils/math';
import { playlistQuestionNumberSelector } from '../../../../store/attributes/playlistStats/playlistStats.selector';
import { biteItemSelector } from '../../../../store/bite/bite.selectors';

interface IProps {
  details: PlaylistUserStats;
  onClose: () => void;
}

const PlaylistUserDetailsBottomSheet: React.FC<IProps> = ({ details, onClose }) => {
  const isWebRtl = getIsWebRtl();

  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const playlistQuestionNumber = useSelector(playlistQuestionNumberSelector);

  const stats = useMemo(() => {
    if (!details) {
      return null;
    }

    return {
      progress: details.activity.filter((bite) => !!bite.bite_progress).length,
      success: details.activity.filter((bite) => bite.has_question && bite.answer === 'correctly').length,
      successTotal: details.activity.filter((bite) => bite.has_question && bite.answer !== null).length,
      progressTotal: playlist?.bite_shares.length,
    };
  }, [details, playlist]);

  const activityMap = useMemo(
    () =>
      details?.activity.reduce((acc, activity) => {
        acc[activity.bite_share_id] = activity;
        return acc;
      }, {}),
    [details?.activity],
  );

  const keyExtractor = useCallback((item) => item.id, []);

  const renderItem = useCallback(
    ({ item }) => <PlaylistBiteDetails activity={activityMap?.[item.id]} biteShare={item} />,
    [activityMap],
  );

  return (
    <AnimatedBottomSheet
      isVisible={!!details}
      onClose={onClose}
      maxHeight={deviceHeight * 0.7}
      minHeight={300}
      withMinFixedHeight
    >
      <SafeAreaView>
        <S.UserDetailsContainer>
          <S.CloseButton onPress={onClose}>
            <CloseIcon width={14} height={14} fill={'#979797'} />
          </S.CloseButton>
          <S.Header>
            <UserCard user={details?.user} />
          </S.Header>
          <S.Content>
            <S.ListHeader>
              {!!stats && (
                <S.IconsContainer isRtl={isWebRtl}>
                  <S.HeaderIcon>
                    <S.IconContainer isRtl={isWebRtl}>
                      <ProgressIcon />
                    </S.IconContainer>
                    <S.TextContainer>
                      <S.Text>
                        {stats.progress} / {stats.progressTotal}
                      </S.Text>
                      <S.Text bold>{`${Math.round((stats.progress / stats.progressTotal) * 100)}%`}</S.Text>
                    </S.TextContainer>
                  </S.HeaderIcon>
                  <S.HeaderIcon>
                    <S.IconContainer isRtl={isWebRtl}>
                      <SuccessIcon />
                    </S.IconContainer>
                    <S.TextContainer>
                      {playlistQuestionNumber ? (
                        <>
                          <S.Text>
                            {stats.success} / {stats.successTotal}
                          </S.Text>
                          <S.Text bold>{safeDivide(stats.success, stats.successTotal)}%</S.Text>
                        </>
                      ) : (
                        <S.Text bold>-</S.Text>
                      )}
                    </S.TextContainer>
                  </S.HeaderIcon>
                </S.IconsContainer>
              )}
            </S.ListHeader>

            <S.Divider />
            <FlatList
              contentContainerStyle={S.PlaylistBiteDetailsListStyles}
              keyExtractor={keyExtractor}
              data={playlist?.bite_shares}
              renderItem={renderItem}
              scrollEnabled
            />
          </S.Content>
        </S.UserDetailsContainer>
      </SafeAreaView>
    </AnimatedBottomSheet>
  );
};

interface IPlaylistBiteDetailsProps {
  activity?: PlaylistUserStats['activity'][0];
  biteShare: IPlaylist['bite_shares'][0];
}

const PlaylistBiteDetails: React.FC<IPlaylistBiteDetailsProps> = ({ activity, biteShare }) => {
  const bite = useSelector(biteItemSelector(biteShare.bite));

  const isWebRtl = getIsWebRtl();

  const hasQuestion = useMemo(
    () => bite.bite_sections.some((section) => section.type === 'question'),
    [bite.bite_sections],
  );

  const progressIcon = useMemo(() => {
    if (activity?.bite_progress) {
      return <CheckMarkIcon />;
    }

    return null;
  }, [activity]);

  const successIcon = useMemo(() => {
    if (!hasQuestion) {
      return <MinusIcon />;
    }

    if (!activity?.answer) {
      return null;
    }

    if (activity.answer === 'incorrectly') {
      return <CrossMarkIcon />;
    }

    return <CheckMarkIcon />;
  }, [activity, hasQuestion]);

  return (
    <>
      <S.PlaylistBiteDetails>
        <S.BiteShareTitle numberOfLines={1}>{biteShare.subject}</S.BiteShareTitle>
        <S.IconsContainer isRtl={isWebRtl}>
          <S.IconContainer isRtl={isWebRtl}>{progressIcon}</S.IconContainer>
          <S.IconContainer isRtl={isWebRtl}>{successIcon}</S.IconContainer>
        </S.IconsContainer>
      </S.PlaylistBiteDetails>
      <S.Divider />
    </>
  );
};

const S = {
  UserDetailsContainer: styled.View`
    padding: ${calcHeight(20)}px ${calcWidth(17)}px;
  `,
  Header: styled.View`
    margin-top: ${calcHeight(17)}px;
    margin-left: ${calcWidth(3)}px;
  `,
  CloseButton: styled.TouchableOpacity`
    position: absolute;
    top: ${calcHeight(19)}px;
    right: ${calcWidth(23)}px;
  `,
  Content: styled.View`
    margin-top: ${calcHeight(10)}px;
  `,
  ListHeader: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${calcHeight(20)}px;
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 ${calcWidth(10)}px;
  `,
  IconContainer: styled.View<{ isRtl: boolean }>`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(80)}px;
    justify-content: center;
  `,
  TextContainer: styled.View`
    margin-top: ${calcHeight(5)}px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  Text: styled.Text<{ bold?: boolean }>`
    margin-right: ${calcWidth(5)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  BiteShareTitle: styled.Text`
    flex: 1;
    margin-right: ${calcWidth(5)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  PlaylistBiteDetails: styled.View`
    height: ${calcHeight(30)}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  Divider: styled.View`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGray32};
  `,
  HeaderIcon: styled.View`
    flex-direction: column;
  `,
  PlaylistBiteDetailsListStyles: {
    flex: 1,
    maxHeight: deviceHeight * 0.7 - calcHeight(160),
  },
};

export default memo(PlaylistUserDetailsBottomSheet);
