import { all, call, delay, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import withRetry from '../../utils/withRetry';
import {
  cloneCloudAssetCacheForTimelineItem,
  createCloudAsset,
  createCloudAssetCache,
  generateTalkingAvatar,
  loadCloudAssets,
  loadVideoAssets,
  processCloudAssetMeta,
  resizeCloudAssetForResolution,
  setCloudAsset,
  setCloudAssetCacheReady,
  toggleIsBrandCloudAsset,
  toggleIsBrandCloudAssetDone,
  toggleIsBrandCloudAssetError,
  toggleIsFavoriteCloudAsset,
  toggleIsFavoriteCloudAssetDone,
  toggleIsFavoriteCloudAssetError,
} from './cloudAssets.slice';
import { activeOrganizationSelector, authSelector, userSelector } from '../auth/auth.selectors';
import {
  addCloudAssetToBrand,
  addCloudAssetToFavorites,
  createCloudAsset as createCloudAssetService,
  downloadFileService,
  generateTalkingAvatarService,
  getCloudAsset,
  getCloudAssetProcessingTask,
  getTalkingAvatarService,
  processCloudAssetMeta as processCloudAssetMetaService,
  removeCloudAssetFromBrand,
  removeCloudAssetFromFavorites,
  resizeCloudAssetForResolution as resizeCloudAssetForResolutionService,
} from '../api/bites-api/calls/cloudAssets.calls';
import { log, logError } from '../appActivity/appActivity.slice';
import { AxiosResponse } from 'axios';
import {
  ICloudAsset,
  ICreateCloudAssetPayload,
  IGenerateTalkingAvatarServiceResult,
  IGenerateTalkingAvatar,
  ICreateCloudAssetCache,
  // IUpdateVideoWithCloudAssetSaga,
  IGetCloudAssets,
  ICloneCloudAssetCacheForTimelineItem,
  IResizeCloudAssetForResolutionPayload,
  IResizeCloudAssetForResolutionTask,
  IProcessCloudAssetMetaPayload,
  UploadCloudAssetToS3Payload,
  ToggleIsFavoriteCloudAsset,
  ToggleIsBrandCloudAsset,
  LoadCloudAssets,
} from './cloudAssets.types';
import { PayloadAction } from '@reduxjs/toolkit';
import uploadToS3 from '../../utils/uploadToS3';
import { IS_PROD } from '../../utils/constants/env';
import {
  timelineItemSeletor,
  videoResolutionSeletor,
  videoSelector,
  videoTimelineLayersSeletor,
} from '../videoEditor/videoEditor.selectors';
import { v4 as uuid } from 'uuid';
import {
  ICloudAssetAudioCache,
  ICloudAssetVideoCache,
  TCloudAssetCache,
  cloneCloudAssetCacheForAudioTimelineItem,
  cloneCloudAssetCacheForVideoTimelineItem,
  cloudAssetCache,
} from './cloudAssets.cache';
import store from '..';
import { getImageMetadata } from '../../utils/getImageMetadata';
import { getVideoMetadata } from '../../utils/getVideoMetadata';
import { ITimelineItem, ITimelineLayer, IVideoConfig } from '../videoEditor/videoEditor.types';
// import 'gifler';
// import gifFrames from 'gif-frames';
import { parseGIF, decompressFrames } from 'gifuct-js';
import { cloudAssetSelector, toggleIsBrandByIdSelector, toggleIsFavoriteByIdSelector } from './cloudAssets.selector';
import { getErrorLogData } from '../../utils/getErrorLogData';
import { getTimelineItemsByCloudAssetIdSaga } from '../videoEditor/videoEditor.saga';
import { getResizeTarget } from '../../screens/videoEditor/utils/getResizeTarget';
import { BASE_BACKEND_URL } from '../api/bites-api';

function* createCloudAssetSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetPayload>, 'payload'>) {
  const {
    fileType,
    fileMeta,
    file,
    previewImageFile,
    dataUrl,
    blob,
    sourceMeta,
    originalSrc,
    originalData,
    isBrandAsset,
    onCreate,
    onCacheReady,
    onFail,
  } = payload;
  const processId = payload.processId || uuid();

  try {
    const org = yield select(activeOrganizationSelector);
    const user = yield select(authSelector);

    yield put(
      log({
        event: 'createCloudAssetSaga: start',
        processId,
        data: {
          fileType,
        },
      }),
    );

    const storage = yield call(uploadCloudAssetToS3Saga, {
      payload: {
        processId,
        file,
      },
    });

    yield put(
      log({
        event: 'createCloudAssetSaga: uploaded to s3',
        processId,
        data: {
          storage,
        },
      }),
    );

    const keyParts = storage.key.split('/');
    keyParts.shift(); // remove the orgId
    keyParts.pop(); // remove the file name
    const previewImageKey = keyParts.join('/') + '/thumbnail.png';
    const previewImage = previewImageFile
      ? yield call(uploadCloudAssetToS3Saga, {
          payload: {
            processId,
            file: previewImageFile,
            key: previewImageKey,
            contentType: 'image/png',
          },
        })
      : undefined;

    // if (!videoId) {
    //   yield put(
    //     log({
    //       event: 'createCloudAssetSaga: no video',
    //       processId,
    //       data: {
    //         taskId,
    //         s3Bucket,
    //         s3Url,
    //       },
    //     }),
    //   );

    //   video = yield saveVideoSaga({ processId });
    //   videoId = video.id;

    //   yield put(
    //     log({
    //       event: 'createCloudAssetSaga: created video',
    //       processId,
    //       data: {
    //         video,
    //       },
    //     }),
    //   );
    // }

    const cloudAsset: ICloudAsset = {
      fileType,
      fileMeta,
      storage,
      previewImage,
      orgId: org.id,
      creatorId: user.id,
      sourceMeta,
      originalSrc,
      originalData,
      isBrandAsset,
    };

    yield put(
      log({
        event: 'createCloudAssetSaga: creating cloud asset',
        processId,
        data: {
          cloudAsset,
        },
      }),
    );

    const {
      data: { cloudAsset: newCloudAsset },
    }: AxiosResponse<{ cloudAsset: ICloudAsset }> = yield withRetry(() => createCloudAssetService(cloudAsset), {
      errorContext: {
        data: {
          action: 'createCloudAssetSaga',
        },
      },
    });

    yield put(setCloudAsset({ cloudAsset: newCloudAsset }));

    yield put(
      log({
        event: 'createCloudAssetSaga: created cloud asset',
        processId,
        data: {
          newCloudAsset,
        },
      }),
    );

    if (typeof onCacheReady === 'function') {
      yield spawn(createCloudAssetCacheSaga, {
        payload: {
          processId,
          cloudAsset: newCloudAsset,
          file,
          dataUrl,
          blob,
          onCacheReady,
          onFail,
        },
      });
    }

    // yield spawn(updateVideoWithCloudAssetSaga, {
    //   payload: {
    //     processId,
    //     videoId,
    //     video,
    //     cloudAsset: newCloudAsset,
    //   },
    // });

    yield put(
      log({
        event: 'createCloudAssetSaga: done',
        processId,
      }),
    );

    if (onCreate) {
      onCreate({
        cloudAsset: newCloudAsset,
      });
    }

    return [newCloudAsset];
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );

    if (onFail) {
      onFail({
        error,
      });
    }
  }
}

function* uploadCloudAssetToS3Saga({ payload }: Pick<PayloadAction<UploadCloudAssetToS3Payload>, 'payload'>) {
  const { file, key, contentType } = payload;
  const processId = payload.processId || uuid();

  const org = yield select(activeOrganizationSelector);
  // const user = yield select(authSelector);

  try {
    const { taskId } = yield uploadToS3({
      file,
      processId,
      orgId: org.id,
      endpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/create?${key ? `key=${key}` : ''}${
        contentType ? `&contentType=${contentType}` : ''
      }`,
      multiPartEndpoints: {
        preSignEndpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/multipart/create?orgId=${
          org.id
        }${key ? `&key=${key}` : ''}${contentType ? `&contentType=${contentType}` : ''}`,
        completeEndpoint: `${BASE_BACKEND_URL}/api/common_services/cloud_asset/s3/upload/multipart/complete`,
      },
      mediaType: file.type?.split('/')[0] as 'image' | 'video' | 'audio',
    });

    const s3Bucket = IS_PROD ? 'cloud-assets-production' : 'cloud-assets-staging';
    const s3Key = key ? `${org.id}/${key}` : taskId;
    const s3Url = `https://${s3Bucket}.s3.amazonaws.com/${s3Key}`;

    yield put(
      log({
        event: 'uploadCloudAssetToS3Saga: uploaded to s3',
        processId,
        data: {
          taskId,
          s3Bucket,
          s3Url,
        },
      }),
    );

    return {
      type: 's3',
      url: s3Url,
      bucket: s3Bucket,
      key: s3Key,
      // taskId,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'uploadCloudAssetToS3Saga: error',
        data: {
          error,
        },
      }),
    );

    throw error;
  }
}

const getStorageForResolution = (cloudAsset: ICloudAsset, resolution: Partial<IVideoConfig['resolution']>) => {
  const resized = cloudAsset.storageByResolution?.find((storageByResolution) =>
    resolution.height
      ? storageByResolution.resolution.height === resolution.height
      : storageByResolution.resolution.width === resolution.width,
  );

  if (resized) {
    return resized.storage;
  }

  if (
    (resolution.height && cloudAsset.fileMeta.height <= resolution.height) ||
    (resolution.width && cloudAsset.fileMeta.width <= resolution.width)
  ) {
    return cloudAsset.storage;
  }

  return null;
};

function* resizeCloudAssetForResolutionSaga({
  payload,
}: Pick<PayloadAction<IResizeCloudAssetForResolutionPayload>, 'payload'>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;

  const resolution = yield select(videoResolutionSeletor);
  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(cloudAssetId));

  if (!cloudAsset.fileMeta?.width || !cloudAsset.fileMeta?.height) {
    yield put(
      logError({
        event: 'resizeCloudAssetForResolutionSaga: error - no width or height',
        processId,
        data: {
          cloudAssetId,
        },
      }),
    );
    return;
  }

  const resizeTarget = getResizeTarget(resolution);

  const storage = getStorageForResolution(cloudAsset, resizeTarget);

  // for video we need to have the storage from storageByResolution not the original one,
  // since storageByResolution references files processed also for HDR
  if (storage && (storage !== cloudAsset.storage || cloudAsset.fileType !== 'video')) {
    yield put(
      log({
        event: 'resizeCloudAssetForResolutionSaga: no need to resize',
        processId,
        data: {
          resolution,
          cloudAsset,
        },
      }),
    );
    return;
  }

  try {
    const {
      data: { taskId },
    }: AxiosResponse<{
      taskId: string;
    }> = yield withRetry(
      () =>
        resizeCloudAssetForResolutionService({
          cloudAssetId,
          resolution: resizeTarget,
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'resizeCloudAssetForResolutionSaga',
            cloudAssetId,
            resolution: resizeTarget,
          },
        },
      },
    );

    yield cloudAssetProcessingTaskPollingSaga({
      taskId,
      cloudAssetId,
      processId,
    });
  } catch (error) {
    yield put(
      logError({
        event: 'resizeCloudAssetForResolutionSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          resolution,
          cloudAssetId,
        },
      }),
    );
  }
}

function* processCloudAssetMetaSaga({ payload }: Pick<PayloadAction<IProcessCloudAssetMetaPayload>, 'payload'>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;

  yield put(
    log({
      event: 'processCloudAssetMetaSaga: start',
      processId,
      data: {
        cloudAssetId,
      },
    }),
  );

  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(cloudAssetId));

  if (
    ((cloudAsset.fileType === 'image' || cloudAsset.fileType === 'gif') &&
      cloudAsset.fileMeta?.vectorData !== undefined) ||
    ((cloudAsset.fileType === 'audio' || cloudAsset.fileType === 'video') &&
      cloudAsset.fileMeta?.transcription !== undefined &&
      cloudAsset.fileMeta?.vectorData !== undefined)
  ) {
    yield put(
      log({
        event: 'processCloudAssetMetaSaga: no need to process',
        processId,
        data: {
          cloudAssetId,
        },
      }),
    );
    return;
  }

  try {
    const {
      data: { taskId },
    }: AxiosResponse<{
      taskId: string;
    }> = yield withRetry(
      () =>
        processCloudAssetMetaService({
          cloudAssetId,
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'processCloudAssetMetaSaga',
            cloudAssetId,
          },
        },
      },
    );

    yield cloudAssetProcessingTaskPollingSaga({
      taskId,
      cloudAssetId,
      processId,
    });
  } catch (error) {
    yield put(
      logError({
        event: 'processCloudAssetMetaSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );
  }
}

function* cloudAssetProcessingTaskPollingSaga({
  taskId,
  cloudAssetId,
  processId,
}: {
  taskId: string;
  cloudAssetId: ICloudAsset['id'];
  processId: string;
}) {
  yield put(
    log({
      event: 'resizeCloudAssetForResolutionSaga polling: start',
      processId,
      data: {
        taskId,
        cloudAssetId,
      },
    }),
  );

  const startTs = Date.now();
  while (true) {
    try {
      const {
        data: { cloudAssetProcessingTask },
      }: AxiosResponse<{ cloudAssetProcessingTask: IResizeCloudAssetForResolutionTask }> =
        yield getCloudAssetProcessingTask({
          taskId,
        });

      if (cloudAssetProcessingTask.status === 'FAILED') {
        yield put(
          logError({
            event: 'resizeCloudAssetForResolutionSaga polling: failed',
            processId,
            data: {
              taskId,
              cloudAssetId,
            },
          }),
        );
        break;
      }

      if (cloudAssetProcessingTask.status === 'DONE') {
        const {
          data: { cloudAsset: updatedCloudAsset },
        } = yield getCloudAsset({ id: cloudAssetId });

        yield put(
          setCloudAsset({
            cloudAsset: updatedCloudAsset,
          }),
        );

        const timelineItems = yield getTimelineItemsByCloudAssetIdSaga({ cloudAssetId });

        yield put(
          createCloudAssetCache({
            processId,
            cloudAsset: updatedCloudAsset,
            timelineItems,
          }),
        );

        yield put(
          log({
            event: 'resizeCloudAssetForResolutionSaga polling: done',
            processId,
            data: {
              taskId,
              cloudAssetId,
            },
          }),
        );
        break;
      }
    } catch (error) {
      yield put(
        logError({
          event: 'resizeCloudAssetForResolutionSaga: polling error',
          processId,
          data: {
            ...getErrorLogData(error),
            taskId,
            cloudAssetId,
          },
        }),
      );
    } finally {
      if (Date.now() - startTs > 1000 * 60 * 5) {
        throw new Error('Polling timeout');
      }
      yield delay(5000);
    }
  }
}

// function* updateVideoWithCloudAssetSaga({ payload }: Pick<PayloadAction<IUpdateVideoWithCloudAssetSaga>, 'payload'>) {
//   const { videoId, cloudAsset, video } = payload;
//   const processId = payload.processId || uuid();

//   yield put(
//     log({
//       event: 'updateVideoWithCloudAssetSaga',
//       processId,
//       data: {
//         videoId,
//         cloudAsset,
//         video,
//       },
//     }),
//   );

//   try {
//     const currentVideo = yield select(videoSeletor);
//     const assetIds = currentVideo?.id === videoId ? currentVideo.assetIds : video.assetIds;

//     yield updateVideoSaga({
//       payload: {
//         processId,
//         filters: {
//           id: videoId,
//         },
//         update: {
//           assetIds: [...assetIds, cloudAsset.id],
//         },
//       },
//     });

//     yield put(
//       log({
//         event: 'updateVideoWithCloudAssetSaga: done',
//         processId,
//         data: {},
//       }),
//     );
//   } catch (error) {
//     yield put(
//       logError({
//         event: 'updateVideoWithCloudAssetSaga: error',
//         processId,
//         data: {
//           error,
//         },
//       }),
//     );
//   }
// }

export interface IOnCacheReadyProps {
  cloudAsset: ICloudAsset;
  cloudAssetCache: TCloudAssetCache;
}
export type TOnCacheReady = (props: IOnCacheReadyProps) => void;
interface ICreateCloudAssetImageByDataUrlCache {
  processId: number;
  cloudAsset: ICloudAsset;
  dataUrl: string;
}
function* createCloudAssetImageByDataUrlCacheSaga({
  payload,
}: Pick<PayloadAction<ICreateCloudAssetImageByDataUrlCache>, 'payload'>) {
  const { processId, cloudAsset, dataUrl } = payload;

  try {
    const decodedData = atob(dataUrl.split(',')[1]);

    // Create a Uint8Array for the binary data
    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    // Create a blob object
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]; // Extract mime type
    const blob = new Blob([uint8Array], { type: mimeString });

    let blobUrl;
    let image;

    yield new Promise((resolve, reject) => {
      // Create a blob URL
      blobUrl = URL.createObjectURL(blob);

      image = new Image();
      image.src = blobUrl;

      image.addEventListener(
        'load',
        () => {
          resolve(image);
        },
        { once: true },
      );

      // error
      image.addEventListener(
        'error',
        (error: any) => {
          store.dispatch(
            logError({
              event: 'createCloudAssetImageCacheSaga: error',
              processId,
              data: {
                errorMessage: error?.message,
                errorStack: error?.stack,
                cloudAsset,
              },
            }),
          );
          reject(error);
        },
        { once: true },
      );
      // yield new Promise((resolve) => (image.onload = resolve));
    });

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      image,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetImageByDataUrlCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetImageCache {
  processId: number;
  cloudAsset: ICloudAsset;
}
function* createCloudAssetImageCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetImageCache>, 'payload'>) {
  const { processId, cloudAsset } = payload;

  try {
    const url = yield getCloudAssetFileUrl(cloudAsset);

    const { data: blob } = yield downloadFileService(url);

    let image;
    let blobUrl;

    yield new Promise((resolve, reject) => {
      blobUrl = URL.createObjectURL(blob);

      image = new Image();
      image.src = blobUrl;

      image.addEventListener(
        'load',
        () => {
          resolve(image);
        },
        { once: true },
      );

      // error
      image.addEventListener(
        'error',
        (error: any) => {
          store.dispatch(
            logError({
              event: 'createCloudAssetImageCacheSaga: error',
              processId,
              data: {
                errorMessage: error?.message,
                errorStack: error?.stack,
                cloudAsset,
              },
            }),
          );
          reject(error);
        },
        { once: true },
      );

      // yield new Promise((resolve) => (image.onload = resolve));
    });

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      image,
    };
  } catch (error) {
    yield put(
      logError({
        event: 'createCloudAssetImageCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetGifCache {
  processId: number;
  cloudAsset: ICloudAsset;
}
function* createCloudAssetGifCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetGifCache>, 'payload'>) {
  const { processId, cloudAsset } = payload;

  try {
    // const url = yield getCloudAssetFileUrl(cloudAsset);
    const url = cloudAsset.storage.url;

    const { data } = yield downloadFileService(url, {
      responseType: 'arraybuffer',
    });

    const gif = parseGIF(data);
    const frames = decompressFrames(gif, true);

    cloudAssetCache[cloudAsset.id!] = {
      gifFrames: frames,
    };
  } catch (error) {
    console.error(error);

    yield put(
      logError({
        event: 'createGifAssetCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetVideoCache {
  processId: number;
  cloudAsset: ICloudAsset;
}
function* createCloudAssetVideoCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetVideoCache>, 'payload'>) {
  const { processId, cloudAsset } = payload;

  try {
    const url = yield getCloudAssetFileUrl(cloudAsset);

    const { data: blob } = yield downloadFileService(url);

    const blobUrl = URL.createObjectURL(blob);

    // const videoBlob = new Blob([blob], { type: 'video/mp4' });
    // const blobUrl = URL.createObjectURL(videoBlob);

    const getVideo = async () => {
      return withRetry(
        () =>
          new Promise<HTMLVideoElement>((resolve, reject) => {
            const video = window.document.createElement('video');
            video.src = blobUrl;
            video.load();

            video.addEventListener(
              'loadedmetadata',
              () => {
                video.currentTime = 0.1;
                video.currentTime = 0;
                resolve(video);
              },
              { once: true },
            );

            video.addEventListener(
              'error',
              (error: any) => {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetVideoCacheSaga.getVideo: error',
                    processId,
                    data: {
                      errorMessage: error?.message,
                      errorStack: error?.stack,
                      cloudAsset,
                    },
                  }),
                );
                reject(error);
              },
              { once: true },
            );

            video.load();

            setTimeout(() => {
              if (!video.readyState) {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetVideoCacheSaga: video not ready, retrying...',
                    processId,
                    data: {
                      cloudAsset,
                    },
                  }),
                );
                // abort the video loading
                video.src = '';
                reject(new Error('Video not ready'));
              }
            }, 5000);
          }),
        {
          errorContext: {
            action: 'createCloudAssetVideoCacheSaga',
            processId,
            data: {
              cloudAsset,
            },
          },
        },
      );
    };

    const video = yield getVideo();

    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      video,
      getVideo,
    };
  } catch (error: any) {
    console.error(error);

    yield put(
      logError({
        event: 'createCloudAssetVideoCacheSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

interface ICreateCloudAssetAudioCache {
  processId: number;
  cloudAsset: ICloudAsset;
}
function* createCloudAssetAudioCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetAudioCache>, 'payload'>) {
  const { processId, cloudAsset } = payload;

  try {
    const url = cloudAsset.storage.url;

    const { data: blob } = yield downloadFileService(url);

    const blobUrl = URL.createObjectURL(blob);

    const getAudio = async () => {
      return withRetry(
        () =>
          new Promise<HTMLAudioElement>((resolve, reject) => {
            const audio = new Audio(blobUrl);

            audio.addEventListener(
              'canplaythrough',
              () => {
                audio.currentTime = 0.1;
                audio.currentTime = 0;
                resolve(audio);
              },
              { once: true },
            );

            // error
            audio.addEventListener(
              'error',
              (error: any) => {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetAudioCacheSaga: error ',
                    processId,
                    data: {
                      errorMessage: error?.message,
                      errorStack: error?.stack,
                      cloudAsset,
                    },
                  }),
                );
                reject(error);
              },
              { once: true },
            );

            audio.load();

            setTimeout(() => {
              if (!audio.readyState) {
                store.dispatch(
                  logError({
                    event: 'createCloudAssetAudioCacheSaga: Audio not ready, retrying...',
                    processId,
                    data: {
                      cloudAsset,
                    },
                  }),
                );
                // abort the audio loading
                audio.src = '';
                reject(new Error('Audio not ready'));
              }
            }, 5000); // Adjust the timeout as needed
          }),
        {
          errorContext: {
            action: 'createCloudAssetAudioCacheSaga',
            processId,
            data: {
              cloudAsset,
            },
          },
        },
      );
    };

    const audio = yield getAudio();

    // temporary until we set up CF
    cloudAssetCache[cloudAsset.id!] = {
      blobUrl,
      audio,
      getAudio,
    };
  } catch (error) {
    console.error(error);

    yield put(
      logError({
        event: 'createCloudAssetAudioCacheSaga: error',
        processId,
        data: {
          error: cloudAsset?.toString?.(),
          cloudAsset,
        },
      }),
    );

    throw error;
  }
}

function* createCloudAssetCacheSaga({ payload }: Pick<PayloadAction<ICreateCloudAssetCache>, 'payload'>) {
  const { cloudAsset, timelineItems, file, dataUrl, onCacheReady, onFail } = payload;
  const processId = payload.processId || uuid();

  let attempt = 1;
  while (true) {
    try {
      yield put(
        log({
          event: 'createCloudAssetCacheSaga',
          processId,
          data: {
            cloudAsset,
            file,
            withDataUrl: !!dataUrl,
          },
        }),
      );

      if (cloudAsset.fileType === 'image' && dataUrl) {
        yield put(
          resizeCloudAssetForResolution({
            cloudAssetId: cloudAsset.id,
            processId,
          }),
        );

        yield createCloudAssetImageByDataUrlCacheSaga({
          payload: {
            processId,
            cloudAsset,
            dataUrl,
          },
        });
      }

      if (cloudAsset.fileType === 'image') {
        yield put(
          resizeCloudAssetForResolution({
            cloudAssetId: cloudAsset.id,
            processId,
          }),
        );

        yield createCloudAssetImageCacheSaga({
          payload: {
            processId,
            cloudAsset,
          },
        });
      }

      if (cloudAsset.fileType === 'gif') {
        yield createCloudAssetGifCacheSaga({
          payload: {
            processId,
            cloudAsset,
          },
        });
      }

      if (cloudAsset.fileType === 'video') {
        yield createCloudAssetVideoCacheSaga({
          payload: {
            processId,
            cloudAsset,
          },
        });

        yield put(
          resizeCloudAssetForResolution({
            cloudAssetId: cloudAsset.id,
            processId,
          }),
        );
      }

      if (cloudAsset.fileType === 'audio') {
        yield createCloudAssetAudioCacheSaga({
          payload: {
            processId,
            cloudAsset,
          },
        });
      }

      yield put(
        processCloudAssetMeta({
          cloudAssetId: cloudAsset.id,
          processId,
        }),
      );

      if (timelineItems) {
        yield all(
          timelineItems.map((timelineItem) =>
            call(cloneCloudAssetCacheForTimelineItemSaga, {
              payload: {
                timelineItem,
              },
            }),
          ),
        );
      }

      yield put(
        setCloudAssetCacheReady({
          cloudAssetId: cloudAsset.id!,
        }),
      );

      if (onCacheReady) {
        onCacheReady({
          cloudAsset,
          cloudAssetCache: cloudAssetCache[cloudAsset.id!],
        });
      }

      // break the cycle
      return;
    } catch (error: any) {
      yield put(
        logError({
          event: 'createCloudAssetCacheSaga: error',
          processId,
          data: {
            errorMessage: error?.message,
            errorStack: error?.stack,
          },
        }),
      );

      attempt++;

      if (attempt > 3) {
        if (onFail) {
          onFail({
            error,
          });
        }
        return;
      }
    }
  }
}

// interface IGetDataUrlFromBlobSaga {
//   processId: number;
//   blob: Blob;
// }
// function* getDataUrlFromBlobSaga({ payload }: Pick<PayloadAction<IGetDataUrlFromBlobSaga>, 'payload'>) {
//   const { processId, blob } = payload;

//   const reader = new FileReader();

//   return yield new Promise((resolve, reject) => {
//     reader.onabort = () => {
//       reject('file reading was aborted');
//     };

//     reader.onerror = () => {
//       reject('file reading has failed');
//     };

//     reader.onload = function (e) {
//       store.dispatch(
//         log({
//           event: 'getDataUrlFromBlobSaga: onload',
//           processId,
//           data: {
//             withResult: !!e.target?.result,
//           },
//         }),
//       );

//       resolve(e.target?.result?.toString() || '');
//     };

//     reader.readAsDataURL(blob!);
//   });
// }

function* generateTalkingAvatarSaga({ payload }: PayloadAction<IGenerateTalkingAvatar>) {
  const { file, dataUrl, text, onCreate, onCacheReady, onFail } = payload;
  const processId = payload.processId || uuid();

  try {
    yield put(
      log({
        event: 'generateTalkingAvatarSaga',
        processId,
        data: {
          file,
          text,
        },
      }),
    );

    const imageMetadata = yield getImageMetadata({ url: dataUrl });

    const video = yield select(videoSelector);
    const videoId = video?.id;
    const [avatarCloudAsset] = yield createCloudAssetSaga({
      payload: {
        processId,
        fileType: 'image',
        fileMeta: imageMetadata,
        file,
        dataUrl,
        originalSrc: 'local',
        originalData: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      },
    });

    yield put(
      log({
        event: 'generateTalkingAvatarSaga: created avatar asset',
        processId,
        data: {
          avatarCloudAsset,
        },
      }),
    );

    // const imageUrl = avatarCloudAsset.storage.url = cloudAsset.storage.url;
    // const imageUrl = 'https://mybites.io/wp-content/uploads/2021/04/Eran-Haffatz-scaled-1024x1024.jpg';
    const imageUrl = getCloudAssetImage(avatarCloudAsset);

    const { data: createdTalkResult }: AxiosResponse<IGenerateTalkingAvatarServiceResult> =
      yield generateTalkingAvatarService({
        text,
        avatar: imageUrl,
      });

    yield put(
      log({
        event: 'generateTalkingAvatarSaga: createdTalkResult',
        processId,
        data: {
          createdTalkResult,
        },
      }),
    );

    const talkId = createdTalkResult.id;

    let talkResult: any = null;
    while (!talkResult?.result_url) {
      if (talkResult?.status === 'error') {
        yield put(
          log({
            event: 'generateTalkingAvatarSaga: d-id error',
            processId,
            data: {
              talkResult,
            },
          }),
        );

        throw new Error('d-id error');
      }

      yield delay(1000);

      const currentVideo = yield select(videoSelector);
      if (currentVideo?.id !== videoId) {
        yield put(
          log({
            event: 'generateTalkingAvatarSaga: getTalkingAvatar video changed',
            processId,
          }),
        );
        break;
      }

      try {
        const { data } = yield getTalkingAvatarService(talkId);
        talkResult = data;

        yield put(
          log({
            event: 'generateTalkingAvatarSaga: getTalkingAvatarService done',
            processId,
            data: {
              talkResult,
            },
          }),
        );
      } catch (error) {
        yield put(
          log({
            event: 'generateTalkingAvatarSaga: getTalkingAvatarService error',
            processId,
            data: {
              error,
            },
          }),
        );
      }
    }

    const { data: downloadedBlob } = yield downloadFileService(talkResult.result_url);
    const videoMetadata = yield getVideoMetadata({ blob: downloadedBlob });

    // const result_url =
    //   'https://d-id-talks-prod.s3.us-west-2.amazonaws.com/auth0%7C656505a3f7ce008a8eb1decc/tlk_B0quNxCNz9Awi6TTwKYUN/1701125171355.mp4?AWSAccessKeyId=AKIA5CUMPJBIK65W6FGA&Expires=1701211574&Signature=WHxaTn8bDL7QX3Dbp6f8Bj5MjQw%3D&X-Amzn-Trace-Id=Root%3D1-65651c36-26007c4270022fb21d65d1b3%3BParent%3Df35402576c47401f%3BSampled%3D1%3BLineage%3D6b931dd4%3A0';
    // const { data: downloadedBlob } = yield downloadFileService(result_url);

    const downloadedFile = new File([downloadedBlob], 'downloaded_video.mp4', { type: downloadedBlob.type });

    yield put(
      log({
        event: 'generateTalkingAvatarSaga: downloaded video',
        processId,
      }),
    );

    const [videoCloudAsset] = yield createCloudAssetSaga({
      payload: {
        processId,
        videoId,
        fileType: 'video',
        fileMeta: videoMetadata,
        file: downloadedFile,
        originalSrc: 'd-id',
        originalData: talkResult,
        blob: downloadedBlob,
        onCreate,
        onCacheReady,
      },
    });

    yield put(
      log({
        event: 'generateTalkingAvatarSaga: done',
        processId,
        data: {
          videoCloudAsset,
        },
      }),
    );

    return [videoCloudAsset];
  } catch (error) {
    yield put(
      logError({
        event: 'generateTalkingAvatarSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );

    if (onFail) {
      onFail({
        error,
      });
    }
  }
}

export function* getCloudAssetIdsFromTimelineLayers(payload: {
  cloudAssetIdsMap?: Record<string, ITimelineItem[]>;
  timelineLayers: ITimelineLayer[];
}) {
  const { cloudAssetIdsMap = {}, timelineLayers } = payload;

  for (let layer of timelineLayers) {
    const { timeline } = layer;

    for (let timelineItemId of timeline) {
      const timelineItem = yield select(timelineItemSeletor(timelineItemId));

      if (timelineItem.type === 'group') {
        yield getCloudAssetIdsFromTimelineLayers({
          cloudAssetIdsMap,
          timelineLayers: timelineItem.timelineLayers,
        });

        continue;
      }

      if (!timelineItem.cloudAssetId) {
        continue;
      }

      const { cloudAssetId } = timelineItem;
      cloudAssetIdsMap[cloudAssetId] = cloudAssetIdsMap[cloudAssetId] || [];
      cloudAssetIdsMap[cloudAssetId].push(timelineItem);
    }
  }

  return cloudAssetIdsMap;
}

export function* loadCloudAssetsSaga({ payload }: Pick<PayloadAction<LoadCloudAssets>, 'payload'>) {
  const { cloudAssetIds } = payload;
  const processId = payload.processId || uuid();

  try {
    const promisesResults = yield Promise.allSettled(
      cloudAssetIds.map((cloudAssetId) => {
        return withRetry(() => getCloudAsset({ id: cloudAssetId }), {
          errorContext: {
            data: {
              processId,
              action: 'loadCloudAssetsSaga',
              data: {
                cloudAssetId,
              },
            },
          },
        });
      }),
    );

    const results = promisesResults
      .map((promiseResult) => {
        if (promiseResult.status === 'fulfilled') {
          return promiseResult.value;
        }

        return null;
      })
      .filter(Boolean);

    for (let result of results) {
      yield put(setCloudAsset({ cloudAsset: result.data.cloudAsset }));
    }

    if (results.length < cloudAssetIds.length) {
      throw new Error('Some assets failed to load');
    }
  } catch (error) {
    logError({
      event: 'loadCloudAssetsSaga: error',
      processId,
      data: {
        ...getErrorLogData(error),
        cloudAssetIds,
      },
    });
  }
}

export function* loadVideoAssetsSaga({ payload }: Pick<PayloadAction<IGetCloudAssets>, 'payload'>) {
  const { onDone, onFail } = payload;
  const processId = payload.processId || uuid();

  try {
    const timelineLayers: ITimelineLayer[] = yield select(videoTimelineLayersSeletor);

    const cloudAssetIdsMap: Record<string, ITimelineItem[]> = yield getCloudAssetIdsFromTimelineLayers({
      timelineLayers,
    });

    const cloudAssetIds = Object.keys(cloudAssetIdsMap);
    const results = yield all(
      cloudAssetIds.map((cloudAssetId) => {
        return withRetry(() => getCloudAsset({ id: cloudAssetId }), {
          errorContext: {
            data: {
              processId,
              action: 'loadVideoAssetsSaga: loadAssetsSaga',
              data: {
                cloudAssetId,
              },
            },
          },
        });
      }),
    );

    const promises: Promise<any>[] = [];

    for (let result of results) {
      const { cloudAsset } = result.data;

      yield put(setCloudAsset({ cloudAsset }));

      const timelineItems = cloudAssetIdsMap[cloudAsset.id];

      const promise = new Promise((resolve) => {
        store.dispatch(
          createCloudAssetCache({
            processId,
            cloudAsset,
            timelineItems,
            onCacheReady: async () => {
              resolve(true);
            },
          }),
        );
      });
      promises.push(promise);
    }

    yield all(promises);

    yield put(
      log({
        event: 'loadAssetsSaga: done',
        processId,
      }),
    );

    if (onDone) {
      onDone();
    }
  } catch (error: any) {
    yield put(
      logError({
        event: 'loadAssetsSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    if (onFail) {
      onFail();
    }
  }
}

export const getCloudAssetImage = (cloudAsset: ICloudAsset) => {
  return cloudAsset.fileType === 'image'
    ? cloudAsset.originalSrc === 'pexels'
      ? cloudAsset.originalData.src.original
      : cloudAsset.originalSrc === 'giphy'
      ? cloudAsset.originalData.images.original?.webp // || cloudAsset.originalData.images.original.url
      : 'https://mybites.io/wp-content/uploads/2021/04/Eran-Haffatz-scaled-1024x1024.jpg'
    : // : 'https://herolo-bites2-dev.s3.amazonaws.com/bite/covers/xRpxnr2z4.jpeg'
    cloudAsset.fileType === 'video'
    ? cloudAsset.originalSrc === 'pexels'
      ? cloudAsset.originalData.image
      : cloudAsset.originalSrc === 'd-id'
      ? cloudAsset.originalData.source_url
      : null
    : null;
  // : cloudAsset.storage.url;
};

function* getCloudAssetFileUrl(cloudAsset: ICloudAsset) {
  const resolution = yield select(videoResolutionSeletor);

  const resizeTarget: Partial<IVideoConfig['resolution']> = {};

  if (resolution.width > resolution.height) {
    resizeTarget.height = resolution.height;
  } else {
    resizeTarget.width = resolution.width;
  }

  const storage = getStorageForResolution(cloudAsset, resizeTarget);
  const url = storage?.url || cloudAsset.storage.url;

  return url;
  //   return cloudAsset.storage.url;
  //   // return cloudAsset.fileType === 'gif' && cloudAsset.originalSrc === 'giphy'
  //   //   ? cloudAsset.originalData.images.original?.url
  //   //   : cloudAsset.fileType === 'image'
  //   //   ? cloudAsset.originalSrc === 'pexels'
  //   //     ? cloudAsset.originalData.src.original
  //   //     : // : cloudAsset.originalSrc === 'giphy'
  //   //       // ? cloudAsset.originalData.images.original?.webp
  //   //       // cloudAsset.originalData.images.downsized?.url
  //   //       'https://mybites.io/wp-content/uploads/2021/04/Eran-Haffatz-scaled-1024x1024.jpg'
  //   //   : // : 'https://herolo-bites2-dev.s3.amazonaws.com/bite/covers/xRpxnr2z4.jpeg'
  //   //   cloudAsset.fileType === 'video'
  //   //   ? cloudAsset.originalSrc === 'pexels'
  //   //     ? getPexelsVideo(cloudAsset.originalData)
  //   //     : cloudAsset.originalSrc === 'd-id'
  //   //     ? cloudAsset.originalData.result_url
  //   //     : 'https://player.vimeo.com/progressive_redirect/playback/888475267/rendition/360p/file.mp4?loc=external&oauth2_token_id=1351764088&signature=a8ff50ea8b297a93a545fc21d4599d6f2fa2ac34740247fd27d9ea2369e3c2c3'
  //   //   : 'https://mybites.io/wp-content/uploads/2021/04/Eran-Haffatz-scaled-1024x1024.jpg';
  //   // // : cloudAsset.storage.url;
}

// const getPexelsVideo = (pexelsData) => {
//   const videos = [...pexelsData.video_files];

//   if (videos[0].width > videos[0].height) {
//     videos.sort((a, b) => b.width - a.width);
//     const url = videos.find((video) => video.width <= 720)?.link;

//     if (url) {
//       return url;
//     }

//     videos.sort((a, b) => a.width - b.width);
//     return videos[0].link;
//   }

//   videos.sort((a, b) => b.height - a.height);
//   const url = videos.find((video) => video.height <= 720)?.link;

//   if (url) {
//     return url;
//   }

//   videos.sort((a, b) => a.height - b.height);
//   return videos[0].link;
// };

export function* cloneCloudAssetCacheForTimelineItemSaga({
  payload: { timelineItem },
}: Pick<PayloadAction<ICloneCloudAssetCacheForTimelineItem>, 'payload'>) {
  if (!timelineItem.cloudAssetId) {
    return;
  }

  const originalCache = cloudAssetCache[timelineItem.cloudAssetId];
  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));

  if (cloudAsset.fileType === 'video') {
    yield cloneCloudAssetCacheForVideoTimelineItem(originalCache as ICloudAssetVideoCache, timelineItem.id);
  }
  if (cloudAsset.fileType === 'audio') {
    yield cloneCloudAssetCacheForAudioTimelineItem(originalCache as ICloudAssetAudioCache, timelineItem.id);
  }
}

function* toggleIsFavoriteCloudAssetSaga({ payload }: PayloadAction<ToggleIsFavoriteCloudAsset>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;
  try {
    const toggleIsFavoriteState = yield select(toggleIsFavoriteByIdSelector);
    if (toggleIsFavoriteState.isLoading) {
      return;
    }

    const cloudAsset = yield select(cloudAssetSelector(cloudAssetId));

    const user = yield select(userSelector);

    const isFavorite = !!cloudAsset.favoriteByUserIds?.includes(user.id);

    if (!isFavorite) {
      yield addCloudAssetToFavorites(cloudAsset.id);
    } else {
      yield removeCloudAssetFromFavorites(cloudAsset.id);
    }

    const cloudAsset2 = yield select(cloudAssetSelector(cloudAssetId));

    yield put(
      setCloudAsset({
        cloudAsset: {
          ...cloudAsset2,
          favoriteByUserIds: isFavorite
            ? cloudAsset2.favoriteByUserIds?.filter((id) => id !== user.id)
            : [...(cloudAsset2.favoriteByUserIds || []), user.id],
        },
      }),
    );

    yield put(
      toggleIsFavoriteCloudAssetDone({
        cloudAssetId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'toggleIsFavoriteCloudAssetSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );

    yield put(
      toggleIsFavoriteCloudAssetError({
        cloudAssetId,
        error,
      }),
    );
  }
}

function* toggleIsBrandCloudAssetSaga({ payload }: PayloadAction<ToggleIsBrandCloudAsset>) {
  const processId = payload.processId || uuid();
  const { cloudAssetId } = payload;
  try {
    const toggleIsBrandState = yield select(toggleIsBrandByIdSelector);
    if (toggleIsBrandState.isLoading) {
      return;
    }

    const cloudAsset = yield select(cloudAssetSelector(cloudAssetId));

    if (!cloudAsset.isBrandAsset) {
      yield addCloudAssetToBrand(cloudAsset.id);
    } else {
      yield removeCloudAssetFromBrand(cloudAsset.id);
    }

    const cloudAsset2 = yield select(cloudAssetSelector(cloudAssetId));

    yield put(
      setCloudAsset({
        cloudAsset: {
          ...cloudAsset2,
          isBrandAsset: !cloudAsset2.isBrandAsset,
        },
      }),
    );

    yield put(
      toggleIsBrandCloudAssetDone({
        cloudAssetId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'toggleIsBrandCloudAssetSaga: error',
        processId,
        data: {
          ...getErrorLogData(error),
          cloudAssetId,
        },
      }),
    );

    yield put(
      toggleIsBrandCloudAssetError({
        cloudAssetId,
        error,
      }),
    );
  }
}

export default function* videoEditorSaga() {
  yield takeEvery(createCloudAsset, createCloudAssetSaga);
  yield takeEvery(createCloudAssetCache, createCloudAssetCacheSaga);
  yield takeEvery(cloneCloudAssetCacheForTimelineItem, cloneCloudAssetCacheForTimelineItemSaga);
  yield takeEvery(generateTalkingAvatar, generateTalkingAvatarSaga);
  yield takeLatest(loadVideoAssets, loadVideoAssetsSaga);
  yield takeEvery(resizeCloudAssetForResolution, resizeCloudAssetForResolutionSaga);
  yield takeEvery(processCloudAssetMeta, processCloudAssetMetaSaga);
  yield takeEvery(toggleIsFavoriteCloudAsset, toggleIsFavoriteCloudAssetSaga);
  yield takeEvery(toggleIsBrandCloudAsset, toggleIsBrandCloudAssetSaga);
  yield takeEvery(loadCloudAssets, loadCloudAssetsSaga);
}
