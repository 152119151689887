import { RootState } from '../../index';

export const playlistStatsOverviewSelector = (state: RootState) => state.playlistStats.overview.data;
export const playlistStatsOverviewLoadingSelector = (state: RootState) => state.playlistStats.overview.isLoading;
export const playlistStatsOverviewErrorSelector = (state: RootState) => state.playlistStats.overview.error;
export const usersDataSelector = (state: RootState) => state.playlistStats.stats.data;
export const usersLoadingSelector = (state: RootState) => state.playlistStats.stats.isLoading;
export const usersNextSelector = (state: RootState) => state.playlistStats.stats.next;
export const usersErrorSelector = (state: RootState) => state.playlistStats.stats.error;
export const isNeedToScrollUpUsersSelector = (state: RootState) => state.playlistStats.stats.isNeedToScrollUp;
export const usersSortBySelector = (state: RootState) => state.playlistStats.stats.sortBy;
export const usersSortDirectionSelector = (state: RootState) => state.playlistStats.stats.sortDirection;
export const playlistQuestionNumberSelector = (state: RootState) => state.playlistStats.playlistQuestionNumber;
