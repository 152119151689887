import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { useSelector } from 'react-redux';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { CustomText } from '../../../../components/shared';
import ViewsIcon from '../../../../assets/icons/feed/views.svg';
import AnswersIcon from '../../../../assets/icons/feed/answers.svg';
import DiscussionIcon from '../../../../assets/icons/feed/discussion.svg';
import { playlistInProgressSelector, playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import UploadingOverlay from '../../../../components/shared/UploadingOverlay';
import WithoutCoverOverlay from '../../../../components/feed/biteItemOverlays/WithoutCoverOverlay';
import { EInProgressStatus, EInProgressTask } from '../../../../types/common';
import OwnerInitials from '../../../../components/feed/biteItemOverlays/OwnerInitials';
import { isContentAdminSelector, userSelector } from '../../../../store/auth/auth.selectors';
import useIsPlaylistOwner from '../../../../hooks/useIsPlaylistOwner';
import { View } from 'react-native';
import PlaylistBite from '../../../../components/playlist/PlaylistBite';
import openPlaylistStats from '../../../../utils/playlist/openPlaylistStats';
import { selectPlaylistItemDataSet } from './component.constants';

interface IProps {
  playlistId: number;
  index?: number;
  onAddCover: () => void;
}

const CARDS_NUM = 4;

const PlaylistItem: FC<IProps> = ({ playlistId, onAddCover, index }) => {
  const isContentAdmin = useSelector(isContentAdminSelector);
  const user = useSelector(userSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const isPlaylistOwner = useIsPlaylistOwner(playlist);

  const isUserCanAddCover = isContentAdmin || isPlaylistOwner;

  const playlistCoverUploadingTask = useSelector(playlistInProgressSelector(playlistId, EInProgressTask.COVER));
  const isLoading = playlistCoverUploadingTask?.status === EInProgressStatus.IN_PROGRESS;

  const image = useMemo(() => ({ uri: playlist?.linked_cover_url || null }), [playlist?.linked_cover_url]);

  const dataSet = useMemo(() => {
    return {
      'intercom-target': `feed-item-${index}`,
      ...selectPlaylistItemDataSet,
    };
  }, [index]);

  const handlePress = useCallback(() => {
    openPlaylistStats({ playlistId });
  }, [playlistId]);

  if (!playlist) {
    return null;
  }

  return (
    <View
      //@ts-ignore
      dataSet={dataSet}
    >
      <S.Container onPress={handlePress}>
        <ShadowedContainer>
          <S.ContentContainer>
            {image.uri ? (
              <S.PlaylistImage source={image} />
            ) : (
              <S.BitesContainer>
                {playlist.bite_shares.slice(0, CARDS_NUM).map((biteShare) => (
                  <PlaylistBite biteShare={biteShare} key={biteShare.id} isSmall />
                ))}
                <WithoutCoverOverlay onAddCover={isUserCanAddCover ? onAddCover : null} />
              </S.BitesContainer>
            )}

            <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
          </S.ContentContainer>
          {playlist.creator !== user?.id && <S.OwnerInitials name={playlist.creator_name} />}
        </ShadowedContainer>
        <S.BiteTitle>{playlist.subject}</S.BiteTitle>
        <S.StatsContainer>
          <S.StatsItem>
            <S.StatsCounter>{playlist.watched || 0}</S.StatsCounter>
            <ViewsIcon />
          </S.StatsItem>
          <S.StatsItem>
            <S.StatsCounter>{playlist.success_rate?.toFixed(0) || 0}%</S.StatsCounter>
            <AnswersIcon />
          </S.StatsItem>
          <S.StatsItem>
            <S.StatsCounter>{playlist.comments || 0}</S.StatsCounter>
            <DiscussionIcon />
          </S.StatsItem>
        </S.StatsContainer>
      </S.Container>
    </View>
  );
};

const S = {
  PlaylistImage: styled.ImageBackground`
    position: absolute;
    top: 0;
    left: 0;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    height: ${calcHeight(isWeb ? 276 : 250)}px;
    border-radius: 18px;
    overflow: hidden;
  `,
  Container: styled.TouchableOpacity`
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    margin-left: ${isWeb ? calcWidth(15) : calcWidth(9)}px;
    margin-bottom: ${calcHeight(10)}px;
  `,
  ContentContainer: styled.View`
    overflow: hidden;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    height: ${calcHeight(isWeb ? 276 : 250)}px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.colors.gray25};
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  `,
  BitesContainer: styled.View`
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${calcHeight(4)}px;
  `,
  TypeLabelContainer: styled.View`
    z-index: 1;
    position: absolute;
    padding: ${calcHeight(4)}px ${calcWidth(8)}px;
    top: ${calcHeight(8)}px;
    left: ${calcWidth(8)}px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.blueChoose};
  `,
  TypeLabel: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.OpenSansHebrew};
  `,
  BiteTitle: styled(CustomText)(
    ({ theme }) => css`
      margin-top: ${calcHeight(4)}px;
      padding: 0 ${calcWidth(12)}px;
      font-size: ${theme.fontSizes.s12}px;
      font-weight: bold;
      font-family: ${theme.fontFamilies.Arimo};
    `,
  ),
  StatsContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${calcWidth(12)}px;
  `,
  StatsItem: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  StatsCounter: styled.Text`
    margin-right: ${calcWidth(4)}px;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  OwnerInitials: styled(OwnerInitials)`
    position: absolute;
    bottom: ${calcHeight(10)}px;
    right: ${calcWidth(10)}px;
  `,
};

export default React.memo(PlaylistItem);
