import Types, { IBitePostBody } from './createBite.types';
import { IBiteSettings, IIntroMediaProcessingStatus } from './createBite.types';
import { IIcon } from '../icons/icons.types';
import { IMedia } from '../../types/media';
import { IBiteItem, IEnhancement, IIntroEnhancementsChanges } from '../../types/bite';
import { ISubtitles, ISubtitlesLine, ISubtitlesLines } from '../../types/subtitles';
import { FileWithPath } from 'react-dropzone';
import { IVideoMeta } from '../../hooks/useMedia/useMedia';

export interface IEditBiteAction {
  biteId: number;
  callback?: () => void;
  isPrefilled?: boolean;
}

export interface IUploadVideoAction {
  biteId?: number;
  uploadVideo: () => Promise<any>;
  isEditMode?: boolean;
  isPrefilled?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUploadQuiz {
  playlistSection: 'intro' | 'body';
  callback?: () => void;
  onError?: () => void;
}

export interface IEditBiteIntroVideoAction {
  file?: FileWithPath;
  audioUri?: string;
  videoUri?: string;
  videoMeta?: IVideoMeta;
  withThumbnailUpdate?: boolean;
  thumbnail?: string | File;
  isCancel?: boolean; // an indicator to cancel the main saga from a child without throwing
}

export interface IUpdateBiteAction extends IEditBiteAction {
  body: Partial<IBitePostBody>;
}

export interface IEditBiteIntroMediaAction extends IEditBiteAction {
  bite: Partial<IBitePostBody>;
  media: any;
}

export interface IQuestionSuggestion {
  multiChoice: IMultichoiceSuggestion;
  open: IOpenSuggestion;
  survey: ISurveySuggestion;
}

export interface IMultichoiceSuggestion {
  question: string;
  answers: { text: string; is_correct: boolean }[];
}

export interface IOpenSuggestion {
  question: string;
}

export interface ISurveySuggestion {
  question: string;
  answers: { text: string }[];
}

export interface ISelectContentLocale {
  orgId: number;
  locale: string;
  taskId: string;
  sourceLocale: string;
  onError?: () => void;
}

export interface IUpdateSubtitlesLocale {
  locale: string;
  taskId: string;
}

export const setBiteCover = (coverId: number | null, coverUrl: string | null) => ({
  type: Types.SET_BITE_COVER,
  payload: { coverId, coverUrl },
});

export const startIntroMediaProcessing = ({ taskId }: { taskId: string }) => ({
  type: Types.START_INTRO_MEDIA_PROCESSING,
  payload: { taskId },
});

export const editBiteIntroMediaData = (payload: IEditBiteIntroMediaAction) => ({
  type: Types.EDIT_BITE_INTRO_MEDIA,
  payload,
});

export const updateBiteData = ({ biteId, body, callback }: IUpdateBiteAction) => ({
  type: Types.UPDATE_BITE_DATA,
  payload: { biteId, body, callback },
});

export const editBiteIntroVideo = (payload: IEditBiteIntroVideoAction) => ({
  type: Types.EDIT_BITE_INTRO_VIDEO,
  payload,
});

export const editBiteQuestion = ({ biteId, isPrefilled, callback }: IEditBiteAction) => ({
  type: Types.EDIT_BITE_QUESTION,
  payload: { biteId, isPrefilled, callback },
});

export const editBiteSummary = ({ biteId, callback }: IEditBiteAction) => ({
  type: Types.EDIT_BITE_SUMMARY,
  payload: { biteId, callback },
});

export const deleteBiteIntro = ({ biteId, callback }: IEditBiteAction) => ({
  type: Types.DELETE_BITE_INTRO,
  payload: { biteId, callback },
});

export const deleteBiteSummary = ({ biteId, callback }: IEditBiteAction) => ({
  type: Types.DELETE_BITE_SUMMARY,
  payload: { biteId, callback },
});

export const deleteBiteQuestion = ({ biteId, callback }: IEditBiteAction) => ({
  type: Types.DELETE_BITE_QUESTION,
  payload: { biteId, callback },
});

export const setBiteCoverFromUrl = (url: string) => ({
  type: Types.SET_BITE_COVER_FROM_URL,
  payload: url,
});

export const clearBiteCover = () => ({
  type: Types.CLEAR_BITE_COVER,
});

export const clearBiteMedia = () => ({
  type: Types.CLEAR_BITE_MEDIA,
});

export const setBiteName = (biteName: string) => ({
  type: Types.SET_BITE_NAME,
  payload: biteName,
});

export const setCoverKeywordSuggestion = (coverSuggestion: string) => ({
  type: Types.SET_COVER_KEYWORD_SUGGESTION,
  payload: coverSuggestion,
});

export const setIsNameSuggestionApplied = (payload: boolean) => ({
  type: Types.IS_NAME_SUGGESTION_APPLIED,
  payload,
});

export const setBiteIcon = (biteIcon: IIcon) => ({
  type: Types.SET_BITE_ICON,
  payload: biteIcon,
});

export const toggleBiteSettingItem = (settingKey: keyof IBiteSettings) => ({
  type: Types.TOGGLE_SETTING_ITEM,
  payload: settingKey,
});

export const updateBiteSettings = (settings: Partial<IBiteSettings>) => ({
  type: Types.UPDATE_SETTINGS,
  payload: settings,
});

export const postOrUpdateQuizBiteRequest = (callback: IUploadQuiz) => ({
  type: Types.POST_OR_UPDATE_QUIZ_BITE_REQUEST,
  payload: callback,
});

export const postOrUpdateBiteSuccess = (bite?: IBiteItem) => ({
  type: Types.POST_OR_UPDATE_BITE_SUCCESS,
  payload: bite,
});
export const postOrUpdateBiteError = () => ({
  type: Types.POST_OR_UPDATE_BITE_ERROR,
});

export const setBiteIntroDuration = (duration: number) => ({
  type: Types.SET_BITE_INTRO_DURATION,
  payload: duration,
});
export const setBiteIntro = (media: IMedia | null) => ({
  type: Types.SET_BITE_INTRO,
  payload: media,
});

export const setBiteIntroEdited = (state: boolean) => ({
  type: Types.SET_BITE_INTRO_EDITED,
  payload: state,
});

export const setBiteIntroEnhancementsEdited = (state: boolean) => ({
  type: Types.SET_BITE_INTRO_ENHANCEMENTS_EDITED,
  payload: state,
});

export const setBiteQuestionEdited = (state: boolean) => ({
  type: Types.SET_BITE_QUESTION_EDITED,
  payload: state,
});

export const setBiteSummaryEdited = (state: boolean) => ({
  type: Types.SET_BITE_SUMMARY_EDITED,
  payload: state,
});

export const resetEditedSections = () => ({
  type: Types.RESET_EDITED_SECTIONS,
});

export const setIntroTask = (taskId: string | null) => ({
  type: Types.SET_INTRO_TASK,
  payload: taskId,
});

export const setQuestionTask = (taskId: string | null) => ({
  type: Types.SET_QUESTION_TASK,
  payload: taskId,
});

export const setBiteIntroUploading = (payload: IUploadVideoAction) => ({
  type: Types.SET_BITE_INTRO_UPLOADING,
  payload,
});

export const clearCreateBiteState = () => ({
  type: Types.CLEAR_CREATE_BITE_STATE,
});

export const setCreateBiteFromGenerationScript = (payload: { generationId: string; scriptId: string }) => ({
  type: Types.SET_CREATED_FROM_GENERATION,
  payload,
});

export const setIntroVideoMediaUri = (mediaUri: string) => ({
  type: Types.SET_INTRO_VIDEO_MEDIA_URI,
  payload: mediaUri,
});
export const setIntroMediaProcessingStatus = (payload: Partial<IIntroMediaProcessingStatus>) => ({
  type: Types.SET_INTRO_MEDIA_PROCESSING_STATUS,
  payload,
});
export const setIntroEnhancements = (payload: { enhancements: IEnhancement[]; biteId?: number }) => ({
  type: Types.SET_INTRO_ENHANCEMENTS,
  payload,
});

export const setIntroSubtitles = (payload: ISubtitles[]) => ({
  type: Types.SET_INTRO_SUBTITLES,
  payload,
});

export const fetchIntroSubtitlesLines = (payload: ISubtitles['id']) => ({
  type: Types.FETCH_INTRO_SUBTITLES_LINES,
  payload,
});

export const setFetchingIntroSubtitlesLines = (payload: { id: ISubtitles['id']; isFetching: boolean }) => ({
  type: Types.SET_FETCHING_INTRO_SUBTITLES_LINES,
  payload,
});

export const setIntroSubtitlesLines = (payload: ISubtitlesLines) => ({
  type: Types.SET_INTRO_SUBTITLES_LINES,
  payload,
});

export const defineIntroSubtitlesNullLocale = (payload: IUpdateSubtitlesLocale) => ({
  type: Types.DEFINE_INTRO_SUBTITLES_NULL_LOCALE,
  payload,
});

export const updateIntroSubtitlesLines = (payload: ISubtitlesLine[]) => ({
  type: Types.UPDATE_INTRO_SUBTITLES_LINES,
  payload,
});

export const applyIntroSubtitlesChanges = () => ({
  type: Types.APPLY_INTRO_SUBTITLES_CHANGES,
});

export const setIntroMediaEnabled = (payload: number) => ({
  type: Types.SET_INTRO_MEDIA_ENABLED,
  payload,
});
export const setIntroEnhancementsChanges = (payload: IIntroEnhancementsChanges) => ({
  type: Types.SET_INTRO_ENHANCEMETS_CHANGES,
  payload,
});
export const saveIntroEnhancementsChanges = () => ({
  type: Types.SAVE_INTRO_ENHANCEMETS_CHANGES,
});
export const saveIntroSubtitlesLines = () => ({
  type: Types.SAVE_INTRO_SUBTITLES_LINES,
});

export const setSummarySuggestionCards = (payload: string[]) => ({
  type: Types.SET_SUMMARY_SUGGESTION_CARDS,
  payload,
});

export const resetSummarySuggestionCards = () => ({
  type: Types.RESET_SUMMARY_SUGGESTION_CARDS,
});

export const setQuestionSuggestion = (payload: IQuestionSuggestion) => ({
  type: Types.SET_QUESTION_SUGGESTION,
  payload,
});

export const resetQuestionSuggestion = () => ({
  type: Types.RESET_QUESTION_SUGGESTION,
});

export const selectLocale = (payload: Omit<ISelectContentLocale, 'orgId'>) => ({
  type: Types.SELECT_LOCALE,
  payload,
});

export const createBiteIntroVideoBitePrepared = () => ({
  type: Types.CREATE_BITE_INTRO_VIDEO_BITE_PREPARED,
});
export const createBiteIntroVideoAudioUploaded = () => ({
  type: Types.CREATE_BITE_INTRO_VIDEO_AUDIO_UPLOADED,
});

export const createBiteIntroVideoVideoExported = () => ({
  type: Types.CREATE_BITE_INTRO_VIDEO_VIDEO_EXPORTED,
});

export const saveSubtitlesApprovedState = (payload: {
  id: number;
  approved: boolean;
  onSuccess: (state: boolean) => void;
  onError: () => void;
}) => ({
  type: Types.SAVE_SUBTITLES_APPROVED_STATE,
  payload,
});

export const saveSubtitlesApprovedStateSuccess = ({ id, approved }: { id: number; approved: boolean }) => ({
  type: Types.SAVE_SUBTITLES_APPROVED_STATE_SUCCESS,
  payload: { id, approved },
});
