import Routes from '../../navigation/routes';
import { navigationRef } from '../../navigation/RootNavigation';
import store from '../../store';
import { prepareAnalytics } from '../../store/analytics/analytics.slice';
import { log } from '../../store/appActivity/appActivity.slice';
import {
  getPlaylistDistributions,
  getPlaylistQuestions,
  getPlaylistStatsOverview,
} from '../../store/attributes/playlistStats/playlistStats.slice';
import { getPlaylistOrganizationAttributes } from '../../store/attributes/playlistAttributes/playlistAttributes.slice';
import { EAnalyticsContentType } from '../../store/analytics/analytics.types';

const openPlaylistStats = ({ playlistId }: { playlistId: number }) => {
  store.dispatch(log({ event: 'openPlaylistStats', data: { playlistId } }));

  store.dispatch(prepareAnalytics({ contentId: playlistId, contentType: EAnalyticsContentType.PLAYLIST }));
  store.dispatch(getPlaylistStatsOverview());
  store.dispatch(getPlaylistQuestions());

  const orgId = store.getState().playlist.playlistsMap[playlistId].organization;
  store.dispatch(getPlaylistOrganizationAttributes({ organizationId: orgId }));
  store.dispatch(getPlaylistDistributions());

  navigationRef.current.navigate(Routes.AnalyticsStack.StackName, {
    screen: Routes.AnalyticsStack.PlaylistOverview,
  });
};

export default openPlaylistStats;
